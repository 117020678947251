<template>
  <div v-show="!loading" class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress v-show="awaitDeclarations" indeterminate size="2px" />
    </div>

    <div class="row q-px-md q-py-sm" ref="topBox">
      <div class="col-24 flex justify-between items-center">
        <div class="flex items-center">
          <q-select
            v-model="search.validation"
            :disable="awaitDeclarations"
            :options="validationFilterOptions"
            emit-value
            label="Валидация"
            map-options
            option-label="label"
            option-value="value"
            class="q-mr-md"
            style="min-width: 160px"
            @update:model-value="
              () => {
                filterDeclarations();
                updateDynamicFilters();
              }
            "
          />

          <q-select
            v-model="search.box_bell_ids"
            :disable="awaitDeclarations"
            :options="$store.state.boxBells"
            emit-value
            use-chips
            map-options
            options-dense
            class="q-mr-md"
            multiple
            option-label="title"
            option-value="id"
            label="Отслеживание"
            style="min-width: 160px; max-width: 380px"
            @update:model-value="
              () => {
                filterDeclarations();
                updateDynamicFilters();
              }
            "
          >
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section side>
                  <q-icon
                    :name="getBoxBellIconName(scope.opt.alias)"
                    :color="getBoxBellColor(scope.opt.alias)"
                  />
                </q-item-section>
                <q-item-section>
                  <q-item-label>{{ scope.opt.title }}</q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-icon v-if="scope.selected" name="mdi-check" />
                </q-item-section>
              </q-item>
            </template>
          </q-select>

          <q-select
            v-model="search.related"
            :disable="awaitDeclarations"
            :options="relatedFilterOptions"
            emit-value
            label="Привязанные корпуса"
            map-options
            option-label="label"
            option-value="value"
            style="min-width: 150px"
            @update:model-value="setDeclarationsAndStats"
          />

          <q-checkbox
            v-model="search.only_actual"
            :disable="awaitDeclarations"
            label="Только актуальные"
            @update:model-value="
              () => {
                filterDeclarations();
                updateDynamicFilters();
              }
            "
          />
        </div>

        <div
          class="flex"
          :class="$q.screen.xs || $q.screen.sm ? '' : 'justify-end'"
        >
          <div
            v-if="stats"
            class="flex items-center q-gutter-x-md text-subtitle2"
          >
            <span
              >Проверено:
              <q-badge color="positive">{{ stats.grade_valid }}</q-badge> ({{
                stats.grade_valid_percent
              }}%)</span
            >
            <span
              >Сырых:
              <q-badge color="grey-7">{{ stats.grade_raw }}</q-badge> ({{
                stats.grade_raw_percent
              }}%)</span
            >
            <span
              >С ошибками:
              <q-badge color="red" outline>{{
                stats.with_validation_errors
              }}</q-badge>
              ({{ stats.with_validation_errors_percent }}%)</span
            >
          </div>

          <q-btn
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Справка'"
            flat
            icon="mdi-information-outline"
            @click="showDeclarationInfoDialog"
          />

          <q-btn
            icon="mdi-sort-variant-remove"
            title="Сбросить сортировку колонок"
            @click="
              pagination.sort = {};
              setDeclarationsAndStats(false);
            "
          />

          <q-btn
            :disable="awaitDeclarations"
            icon="mdi-refresh"
            title="Обновить данные в таблице"
            @click="setDeclarationsAndStats(false)"
          />

          <q-btn
            :disable="awaitExportExcel || !declarations.length"
            :loading="awaitExportExcel"
            icon="mdi-file-download-outline"
            :label="$q.screen.xs || $q.screen.sm ? 'XLS' : 'Excel'"
            @click="exportExcel"
          />

          <q-btn
            icon="mdi-cog"
            :label="$q.screen.xs || $q.screen.sm ? '' : 'Параметры'"
          >
            <q-menu touch-position transition-hide="" transition-show="">
              <q-list separator>
                <q-item clickable>
                  <q-item-section>
                    <q-select
                      v-model="visibleColumns"
                      :options="columnsForSelect"
                      borderless
                      dense
                      display-value="Показывать столбцы"
                      emit-value
                      map-options
                      multiple
                      option-value="name"
                      options-dense
                      @update:model-value="onInputVisibleColumns"
                    >
                      <template v-slot:before-options>
                        <div class="q-my-md q-mx-xs">
                          <q-btn
                            label="Отметить все"
                            flat
                            @click="makeAllColumnsVisible"
                          />
                          <q-btn
                            label="Стандартный набор"
                            flat
                            @click="makeDefaultColumnsVisible"
                          />
                        </div>
                      </template>

                      <template v-slot:before>
                        <q-icon class="q-mr-sm" name="mdi-eye-check" />
                      </template>

                      <template v-slot:option="scope">
                        <q-item
                          v-if="scope.opt.name !== 'id'"
                          v-bind="scope.itemProps"
                          style="min-width: 20rem"
                        >
                          <q-item-section>
                            <q-item-label>{{ scope.opt.label }}</q-item-label>
                            <q-item-label caption
                              >{{ scope.opt.pd_article }}
                            </q-item-label>
                          </q-item-section>
                          <q-item-section side>
                            <q-icon v-if="scope.selected" name="mdi-check" />
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-item-section>
                </q-item>

                <q-item
                  clickable
                  @click="showDeclarationTableColumnOrderDialog"
                >
                  <q-item-section side>
                    <q-icon name="mdi-numeric" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Порядок столбцов</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
      </div>
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md">
        <div
          aria-label="Декларации"
          class="l-table l-table--bordered-cells l-table--striped l-table--sticky-first-column"
          :style="tableStyles"
          role="table"
        >
          <div
            class="l-table__row min-w-full sticky-top bg-white"
            role="rowgroup"
            style="z-index: 2"
          >
            <template v-for="column in columns" :key="column.name">
              <div
                v-if="visibleColumns.includes(column.name)"
                :style="getCellStyle(column.name)"
                class="l-table__cell"
                role="columnheader"
              >
                <div
                  :class="column.sortName && 'cursor-pointer'"
                  class="flex items-center justify-between text-body3"
                >
                  <div
                    @click="column.sortName && sortByColumn(column.sortName)"
                  >
                    <q-icon
                      v-if="pagination.sort.hasOwnProperty(column.sortName)"
                      :name="
                        pagination.sort[column.sortName]
                          ? 'mdi-arrow-down'
                          : 'mdi-arrow-up'
                      "
                    />
                    {{ column.label }}
                  </div>
                  <q-icon
                    v-if="column.tooltip_note"
                    name="mdi-information-outline"
                    class="q-ml-xs"
                    color="primary"
                    size="xs"
                    @click="copyToBuffer(column.tooltip_note)"
                  >
                    <q-tooltip max-width="24rem">
                      {{ column.tooltip_note }}
                    </q-tooltip>
                  </q-icon>
                </div>
              </div>
            </template>
          </div>

          <DeclarationTableFiltersRow
            :cellStyles="cellStyles"
            :search="search"
            :visibleColumns="visibleColumns"
            :declarationGrades="declarationGrades"
            :boxMarks="boxMarks"
            @filter-declarations="filterDeclarations"
            @set-declarations="setDeclarationsAndStats"
          />

          <div
            v-for="declaration in declarations"
            :key="declaration.id"
            class="l-table__row min-w-full relative-position"
            :class="getDeclarationRowClasses(declaration)"
            role="rowgroup"
          >
            <q-menu
              context-menu
              transition-hide=""
              transition-show=""
              @before-show="
                (evt) => onShowDeclarationRowContextMenu(evt, declaration)
              "
              @before-hide="rowContextMenuData = null"
              @click="fieldContextMenuShowed = false"
            >
              <q-list>
                <q-item clickable @mouseenter="fieldContextMenuShowed = true">
                  <q-item-section>{{
                    columns.filter(
                      (i) => i.name === rowContextMenuData.columnName
                    )[0].label
                  }}</q-item-section>
                  <q-item-section side>
                    <q-icon name="mdi-chevron-right" />
                  </q-item-section>

                  <q-menu
                    v-model="fieldContextMenuShowed"
                    anchor="top end"
                    self="top start"
                    auto-close
                  >
                    <q-list>
                      <q-item
                        :disable="
                          awaitDeleting ||
                          declarationUpdating.includes(declaration.id) ||
                          !pdFieldsEditableNames.includes(
                            rowContextMenuData.columnName
                          )
                        "
                        clickable
                        @click="showDeclarationEditFieldDialog(declaration)"
                      >
                        <q-item-section title="Редактировать"
                          >Редактировать
                        </q-item-section>
                      </q-item>

                      <q-item
                        :disable="
                          awaitDeleting ||
                          declarationUpdating.includes(declaration.id) ||
                          !pdFieldsCorrectable
                            .map((i) => i.name)
                            .includes(rowContextMenuData.columnName)
                        "
                        clickable
                        @click="showDeclarationCorrectionAddDialog(declaration)"
                      >
                        <q-item-section title="Добавить правило корректировки"
                          >Добавить Корректировку
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-menu>
                </q-item>

                <q-item
                  :disable="
                    awaitDeleting ||
                    declarationUpdating.includes(declaration.id) ||
                    !declaration.declaration_applied_corrections
                  "
                  clickable
                  @click="showDeclarationAppliedCorrectionsDialog(declaration)"
                >
                  <q-item-section title="Показать примененные корректировки"
                    >Примененные Корректировки
                  </q-item-section>
                </q-item>

                <q-separator />

                <q-item
                  :disable="
                    awaitDeleting ||
                    declarationUpdating.includes(declaration.id)
                  "
                  clickable
                  @click="showDeclarationHistoryDialog(declaration)"
                >
                  <q-item-section title="История изменений декларации"
                    >История изменений
                  </q-item-section>
                </q-item>

                <q-separator />

                <q-item
                  :disable="
                    awaitDeleting ||
                    declarationUpdating.includes(declaration.id) ||
                    declaration.validation === null ||
                    (Array.isArray(declaration.validation) &&
                      declaration.validation.length === 0)
                  "
                  clickable
                  @click="showDeclarationValidationAlertsDialog(declaration)"
                >
                  <q-item-section title="Показать ошибки валидации"
                    >Ошибки валидации
                  </q-item-section>
                </q-item>

                <q-separator />

                <q-item
                  clickable
                  @click="showDeclarationReParseDialog(declaration.id)"
                >
                  <q-item-section side>
                    <q-icon name="mdi-data-matrix-scan" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Перепарсить</q-item-label>
                  </q-item-section>
                </q-item>

                <q-separator />

                <q-item class="q-pa-none">
                  <q-btn-toggle
                    v-model="declaration.declaration_grade.alias"
                    :disable="
                      awaitDeleting ||
                      declarationUpdating.includes(declaration.id)
                    "
                    toggle-color="primary"
                    :options="[
                      { value: 'raw', slot: 'raw' },
                      { value: 'attention', slot: 'attention' },
                      { value: 'valid', slot: 'valid' },
                    ]"
                    @update:model-value="
                      (val) => setGradeForDeclaration(declaration, val)
                    "
                  >
                    <template v-slot:raw>Сырая</template>
                    <template v-slot:attention>
                      <div class="row items-center no-wrap">
                        <div class="text-center">Требует<br />внимания</div>
                        <q-icon right color="orange" name="mdi-alert-rhombus" />
                      </div>
                    </template>
                    <template v-slot:valid>
                      <div class="row items-center no-wrap">
                        <div class="text-center">Проверена</div>
                        <q-icon right color="green" name="mdi-shield-check" />
                      </div>
                    </template>
                  </q-btn-toggle>
                </q-item>
              </q-list>
            </q-menu>

            <div
              v-if="visibleColumns.includes('id')"
              :style="getCellStyle('id')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="id"
            >
              {{ declaration.id }}
            </div>

            <div
              v-if="visibleColumns.includes('box_region_name')"
              :style="getCellStyle('box_region_name')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="box_region_name"
            >
              {{ declaration.box.region_name }}
            </div>

            <div
              v-if="visibleColumns.includes('box_serial')"
              :style="getCellStyle('box_serial')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="box_serial"
            >
              <a
                :href="getBuildingLink(declaration.box.serial)"
                target="_blank"
                title="Открыть корпус на домрф"
                class="l-link"
              >
                {{ declaration.box.serial }}
              </a>
            </div>

            <div
              v-if="visibleColumns.includes('box_mark')"
              :style="getCellStyle('box_mark')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="box_mark"
            >
              <div class="flex items-center" style="flex: 1 0 auto">
                <div v-if="declaration.box.box_mark" class="q-mr-sm">
                  {{
                    declaration.box.box_mark && declaration.box.box_mark.name
                  }}
                </div>
                <q-btn
                  v-if="!declaration.box.ap_boxes"
                  icon="mdi-pencil"
                  flat
                  dense
                  size="sm"
                >
                  <q-popup-edit
                    v-model="declaration.box.box_mark"
                    auto-save
                    v-slot="scope"
                    @save="
                      (item) =>
                        onUpdateDeclarationField(declaration, 'box_mark', item)
                    "
                  >
                    <q-select
                      v-model="scope.value"
                      :options="boxMarks"
                      options-dense
                      dense
                      autofocus
                      option-label="name"
                      @keyup.enter="scope.set"
                    />
                  </q-popup-edit>
                </q-btn>
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('ap_box_serials')"
              :style="getCellStyle('ap_box_serials')"
              class="l-table__cell l-table__cell--bordered pre-line"
              data-column-name="ap_box_serials"
            >
              {{
                declaration.box.ap_boxes &&
                declaration.box.ap_boxes.map((i) => i.serial).join("\n")
              }}
            </div>

            <div
              v-if="visibleColumns.includes('box_ap_project')"
              :style="getCellStyle('box_ap_project')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="box_ap_project"
            >
              <div class="flex items-center">
                <div
                  v-if="declaration.box.ap_project"
                  class="flex flex-wrap q-mr-sm"
                >
                  {{
                    declaration.box.ap_project &&
                    `${declaration.box.ap_project.name} (${declaration.box.ap_project.serial})`
                  }}
                </div>
                <q-btn
                  v-if="!declaration.box.ap_boxes"
                  icon="mdi-pencil"
                  flat
                  dense
                  size="sm"
                >
                  <q-popup-edit
                    v-model="declaration.box.ap_project"
                    auto-save
                    v-slot="scope"
                    @save="
                      (item) =>
                        onUpdateDeclarationField(
                          declaration,
                          'box_ap_project',
                          item
                        )
                    "
                  >
                    <q-select
                      v-model="scope.value"
                      :options="apProjects"
                      options-dense
                      dense
                      autofocus
                      use-input
                      map-options
                      option-label="name"
                      option-value="serial"
                      @filter="filterApProjects"
                      @keyup.enter="scope.set"
                    >
                      <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                          <q-item-section>
                            <q-item-label>{{ scope.opt.name }}</q-item-label>
                            <q-item-label caption>{{
                              scope.opt.serial
                            }}</q-item-label>
                            <q-item-label caption
                              >{{ scope.opt.region_name }}
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </template>
                    </q-select>
                  </q-popup-edit>
                </q-btn>
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('box_name')"
              :style="getCellStyle('box_name')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="box_name"
            >
              {{
                shortness(declaration.box.name || declaration.box.address, 80)
              }}
              <q-icon
                v-if="
                  (declaration.box.name || declaration.box.address).length > 80
                "
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="cursor-pointer"
                @click="
                  copyToBuffer(declaration.box.name || declaration.box.address)
                "
              >
                <q-tooltip max-width="20rem" :delay="500">
                  {{ declaration.box.name || declaration.box.address }}
                </q-tooltip>
              </q-icon>
            </div>

            <div
              v-if="visibleColumns.includes('declaration_grade')"
              :style="getCellStyle('declaration_grade')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="declaration_grade"
            >
              <div class="flex items-center" style="flex: 1 0 auto">
                <q-icon
                  v-if="declaration.declaration_grade.alias === 'valid'"
                  color="green"
                  name="mdi-shield-check"
                  size="sm"
                />
                <q-icon
                  v-if="declaration.declaration_grade.alias === 'attention'"
                  color="orange"
                  name="mdi-alert-rhombus"
                  size="sm"
                />
                <span class="q-mr-sm">{{
                  declaration.declaration_grade.name
                }}</span>

                <q-btn-toggle
                  v-if="declaration.declaration_grade.alias === 'raw'"
                  v-model="declaration.declaration_grade.alias"
                  flat
                  size="md"
                  padding="xs"
                  :disable="
                    awaitDeleting ||
                    declarationUpdating.includes(declaration.id)
                  "
                  :options="[
                    { value: 'valid', slot: 'valid' },
                    { value: 'attention', slot: 'attention' },
                  ]"
                  @update:model-value="
                    (val) => setGradeForDeclaration(declaration, val)
                  "
                >
                  <template v-slot:attention>
                    <q-icon center color="orange" name="mdi-alert-rhombus" />
                  </template>
                  <template v-slot:valid>
                    <q-icon center color="green" name="mdi-shield-check" />
                  </template>
                </q-btn-toggle>

                <q-btn
                  icon="mdi-pencil"
                  flat
                  dense
                  size="sm"
                  v-if="declaration.declaration_grade.alias !== 'raw'"
                >
                  <q-popup-edit
                    v-model="declaration.declaration_grade"
                    auto-save
                    v-slot="scope"
                    @save="
                      (grade) => onSaveDeclarationGrade(declaration, grade)
                    "
                  >
                    <q-select
                      v-model="scope.value"
                      :options="declarationGrades"
                      dense
                      autofocus
                      option-label="name"
                      @keyup.enter="scope.set"
                    />
                  </q-popup-edit>
                </q-btn>
              </div>
            </div>

            <div
              v-if="visibleColumns.includes('pd_file_cdn_url')"
              :style="getCellStyle('pd_file_cdn_url')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="pd_file_cdn_url"
            >
              <a
                :href="declaration.pd_file.cdn_url"
                class="l-link"
                target="_blank"
              >
                ссылка
              </a>
            </div>

            <div
              v-if="visibleColumns.includes('pd_file_external_url_inline')"
              :style="getCellStyle('pd_file_external_url_inline')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="pd_file_external_url_inline"
            >
              <a
                :href="declaration.pd_file.external_url_inline"
                class="l-link"
                target="_blank"
              >
                ссылка
              </a>
            </div>

            <div
              v-if="visibleColumns.includes('pd_number')"
              :style="getCellStyle('pd_number')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="pd_number"
            >
              {{ declaration.pd_number }}
            </div>

            <div
              v-if="visibleColumns.includes('box_pd_order')"
              :style="getCellStyle('box_pd_order')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="box_pd_order"
            >
              {{ declaration.pd_order }}
            </div>

            <div
              v-if="visibleColumns.includes('published_at')"
              :style="getCellStyle('published_at')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="published_at"
            >
              {{ declaration.published_at }}
            </div>

            <div
              v-if="visibleColumns.includes('builder_inn')"
              :style="getCellStyle('builder_inn')"
              :class="getCellClass('builder_inn', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="builder_inn"
            >
              {{ declaration.builder_inn }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'builder_inn'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.builder_inn
                "
                :reason="declaration.validation.builder_inn.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('general_contractors')"
              :style="getCellStyle('general_contractors')"
              :class="getCellClass('general_contractors', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position pre-line"
              data-column-name="general_contractors"
            >
              {{ getGeneralContractors(declaration.general_contractors) }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'general_contractors'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.general_contractors
                "
                :reason="declaration.validation.general_contractors.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('company')"
              :style="getCellStyle('company')"
              :class="getCellClass('company', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="company"
            >
              {{ declaration.company }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes('company')
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="declaration.validation && declaration.validation.company"
                :reason="declaration.validation.company.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('reported_at')"
              :style="getCellStyle('reported_at')"
              :class="getCellClass('reported_at', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="reported_at"
            >
              {{ declaration.reported_at }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'reported_at'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.reported_at
                "
                :reason="declaration.validation.reported_at.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('profit_net')"
              :style="getCellStyle('profit_net')"
              :class="getCellClass('profit_net', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="profit_net"
            >
              {{ rubF(declaration.profit_net) }}
              <q-icon
                v-if="declaration.profit_net"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.profit_net)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.profit_net) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'profit_net'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.profit_net
                "
                :reason="declaration.validation.profit_net.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('accounts_payable')"
              :style="getCellStyle('accounts_payable')"
              :class="getCellClass('accounts_payable', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="accounts_payable"
            >
              {{ rubF(declaration.accounts_payable) }}
              <q-icon
                v-if="declaration.accounts_payable"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.accounts_payable)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.accounts_payable) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'accounts_payable'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.accounts_payable
                "
                :reason="declaration.validation.accounts_payable.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('accounts_receivable')"
              :style="getCellStyle('accounts_receivable')"
              :class="getCellClass('accounts_receivable', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="accounts_receivable"
            >
              {{ rubF(declaration.accounts_receivable) }}
              <q-icon
                v-if="declaration.accounts_receivable"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.accounts_receivable)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.accounts_receivable) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'accounts_receivable'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.accounts_receivable
                "
                :reason="declaration.validation.accounts_receivable.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('object_square')"
              :style="getCellStyle('object_square')"
              :class="getCellClass('object_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="object_square"
            >
              {{ declaration.object_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'object_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.object_square
                "
                :reason="declaration.validation.object_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_square')"
              :style="getCellStyle('living_square')"
              :class="getCellClass('living_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_square"
            >
              {{ declaration.living_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.living_square
                "
                :reason="declaration.validation.living_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_square')"
              :style="getCellStyle('nonliving_square')"
              :class="getCellClass('nonliving_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_square"
            >
              {{ declaration.nonliving_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_square
                "
                :reason="declaration.validation.nonliving_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('total_square')"
              :style="getCellStyle('total_square')"
              :class="getCellClass('total_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="total_square"
            >
              {{ declaration.total_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'total_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.total_square
                "
                :reason="declaration.validation.total_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('cadastrals')"
              :style="getCellStyle('cadastrals')"
              :class="getCellClass('cadastrals', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position pre-line"
              data-column-name="cadastrals"
            >
              {{ getCadastrals(declaration.cadastrals) }}
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.cadastrals
                "
                :reason="declaration.validation.cadastrals.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('construction_cost')"
              :style="getCellStyle('construction_cost')"
              :class="getCellClass('construction_cost', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="construction_cost"
            >
              {{ rubF(declaration.construction_cost) }}
              <q-icon
                v-if="declaration.construction_cost"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.construction_cost)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.construction_cost) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'construction_cost'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.construction_cost
                "
                :reason="declaration.validation.construction_cost.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_escrow_qty')"
              :style="getCellStyle('living_escrow_qty')"
              :class="getCellClass('living_escrow_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_escrow_qty"
            >
              {{ declaration.living_escrow_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_escrow_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_escrow_qty
                "
                :reason="declaration.validation.living_escrow_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_fund_qty')"
              :style="getCellStyle('living_fund_qty')"
              :class="getCellClass('living_fund_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_fund_qty"
            >
              {{ declaration.living_fund_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_fund_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_fund_qty
                "
                :reason="declaration.validation.living_fund_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_other_qty')"
              :style="getCellStyle('living_other_qty')"
              :class="getCellClass('living_other_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_other_qty"
            >
              {{ declaration.living_other_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_other_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_other_qty
                "
                :reason="declaration.validation.living_other_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_escrow_qty')"
              :style="getCellStyle('nonliving_escrow_qty')"
              :class="getCellClass('nonliving_escrow_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_escrow_qty"
            >
              {{ declaration.nonliving_escrow_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_escrow_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_escrow_qty
                "
                :reason="declaration.validation.nonliving_escrow_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_fund_qty')"
              :style="getCellStyle('nonliving_fund_qty')"
              :class="getCellClass('nonliving_fund_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_fund_qty"
            >
              {{ declaration.nonliving_fund_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_fund_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_fund_qty
                "
                :reason="declaration.validation.nonliving_fund_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_other_qty')"
              :style="getCellStyle('nonliving_other_qty')"
              :class="getCellClass('nonliving_other_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_other_qty"
            >
              {{ declaration.nonliving_other_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_other_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_other_qty
                "
                :reason="declaration.validation.nonliving_other_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('parking_escrow_qty')"
              :style="getCellStyle('parking_escrow_qty')"
              :class="getCellClass('parking_escrow_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="parking_escrow_qty"
            >
              {{ declaration.parking_escrow_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'parking_escrow_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.parking_escrow_qty
                "
                :reason="declaration.validation.parking_escrow_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('parking_fund_qty')"
              :style="getCellStyle('parking_fund_qty')"
              :class="getCellClass('parking_fund_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="parking_fund_qty"
            >
              {{ declaration.parking_fund_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'parking_fund_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.parking_fund_qty
                "
                :reason="declaration.validation.parking_fund_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('parking_other_qty')"
              :style="getCellStyle('parking_other_qty')"
              :class="getCellClass('parking_other_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="parking_other_qty"
            >
              {{ declaration.parking_other_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'parking_other_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.parking_other_qty
                "
                :reason="declaration.validation.parking_other_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_escrow_square')"
              :style="getCellStyle('living_escrow_square')"
              :class="getCellClass('living_escrow_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_escrow_square"
            >
              {{ declaration.living_escrow_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_escrow_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_escrow_square
                "
                :reason="declaration.validation.living_escrow_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_fund_square')"
              :style="getCellStyle('living_fund_square')"
              :class="getCellClass('living_fund_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_fund_square"
            >
              {{ declaration.living_fund_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_fund_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_fund_square
                "
                :reason="declaration.validation.living_fund_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_other_square')"
              :style="getCellStyle('living_other_square')"
              :class="getCellClass('living_other_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_other_square"
            >
              {{ declaration.living_other_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_other_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_other_square
                "
                :reason="declaration.validation.living_other_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_escrow_square')"
              :style="getCellStyle('nonliving_escrow_square')"
              :class="getCellClass('nonliving_escrow_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_escrow_square"
            >
              {{ declaration.nonliving_escrow_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_escrow_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_escrow_square
                "
                :reason="declaration.validation.nonliving_escrow_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_fund_square')"
              :style="getCellStyle('nonliving_fund_square')"
              :class="getCellClass('nonliving_fund_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_fund_square"
            >
              {{ declaration.nonliving_fund_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_fund_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_fund_square
                "
                :reason="declaration.validation.nonliving_fund_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_other_square')"
              :style="getCellStyle('nonliving_other_square')"
              :class="getCellClass('nonliving_other_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_other_square"
            >
              {{ declaration.nonliving_other_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_other_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_other_square
                "
                :reason="declaration.validation.nonliving_other_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('parking_escrow_square')"
              :style="getCellStyle('parking_escrow_square')"
              :class="getCellClass('parking_escrow_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="parking_escrow_square"
            >
              {{ declaration.parking_escrow_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'parking_escrow_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.parking_escrow_square
                "
                :reason="declaration.validation.parking_escrow_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('parking_fund_square')"
              :style="getCellStyle('parking_fund_square')"
              :class="getCellClass('parking_fund_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="parking_fund_square"
            >
              {{ declaration.parking_fund_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'parking_fund_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.parking_fund_square
                "
                :reason="declaration.validation.parking_fund_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('parking_other_square')"
              :style="getCellStyle('parking_other_square')"
              :class="getCellClass('parking_other_square', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="parking_other_square"
            >
              {{ declaration.parking_other_square }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'parking_other_square'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.parking_other_square
                "
                :reason="declaration.validation.parking_other_square.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_escrow_price')"
              :style="getCellStyle('living_escrow_price')"
              :class="getCellClass('living_escrow_price', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_escrow_price"
            >
              {{ rubF(declaration.living_escrow_price) }}
              <q-icon
                v-if="declaration.living_escrow_price"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.living_escrow_price)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.living_escrow_price) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_escrow_price'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_escrow_price
                "
                :reason="declaration.validation.living_escrow_price.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_fund_price')"
              :style="getCellStyle('living_fund_price')"
              :class="getCellClass('living_fund_price', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_fund_price"
            >
              {{ rubF(declaration.living_fund_price) }}
              <q-icon
                v-if="declaration.living_fund_price"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.living_fund_price)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.living_fund_price) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_fund_price'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_fund_price
                "
                :reason="declaration.validation.living_fund_price.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_other_price')"
              :style="getCellStyle('living_other_price')"
              :class="getCellClass('living_other_price', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_other_price"
            >
              {{ rubF(declaration.living_other_price) }}
              <q-icon
                v-if="declaration.living_other_price"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.living_other_price)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.living_other_price) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_other_price'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_other_price
                "
                :reason="declaration.validation.living_other_price.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_escrow_price')"
              :style="getCellStyle('nonliving_escrow_price')"
              :class="getCellClass('nonliving_escrow_price', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_escrow_price"
            >
              {{ rubF(declaration.nonliving_escrow_price) }}
              <q-icon
                v-if="declaration.nonliving_escrow_price"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.nonliving_escrow_price)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.nonliving_escrow_price) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_escrow_price'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_escrow_price
                "
                :reason="declaration.validation.nonliving_escrow_price.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_fund_price')"
              :style="getCellStyle('nonliving_fund_price')"
              :class="getCellClass('nonliving_fund_price', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_fund_price"
            >
              {{ rubF(declaration.nonliving_fund_price) }}
              <q-icon
                v-if="declaration.nonliving_fund_price"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.nonliving_fund_price)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.nonliving_fund_price) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_fund_price'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_fund_price
                "
                :reason="declaration.validation.nonliving_fund_price.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_other_price')"
              :style="getCellStyle('nonliving_other_price')"
              :class="getCellClass('nonliving_other_price', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_other_price"
            >
              {{ rubF(declaration.nonliving_other_price) }}
              <q-icon
                v-if="declaration.nonliving_other_price"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.nonliving_other_price)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.nonliving_other_price) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_other_price'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_other_price
                "
                :reason="declaration.validation.nonliving_other_price.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('parking_escrow_price')"
              :style="getCellStyle('parking_escrow_price')"
              :class="getCellClass('parking_escrow_price', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="parking_escrow_price"
            >
              {{ rubF(declaration.parking_escrow_price) }}
              <q-icon
                v-if="declaration.parking_escrow_price"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.parking_escrow_price)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.parking_escrow_price) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'parking_escrow_price'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.parking_escrow_price
                "
                :reason="declaration.validation.parking_escrow_price.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('parking_fund_price')"
              :style="getCellStyle('parking_fund_price')"
              :class="getCellClass('parking_fund_price', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="parking_fund_price"
            >
              {{ rubF(declaration.parking_fund_price) }}
              <q-icon
                v-if="declaration.parking_fund_price"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.parking_fund_price)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.parking_fund_price) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'parking_fund_price'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.parking_fund_price
                "
                :reason="declaration.validation.parking_fund_price.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('parking_other_price')"
              :style="getCellStyle('parking_other_price')"
              :class="getCellClass('parking_other_price', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="parking_other_price"
            >
              {{ rubF(declaration.parking_other_price) }}
              <q-icon
                v-if="declaration.parking_other_price"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.parking_other_price)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.parking_other_price) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'parking_other_price'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.parking_other_price
                "
                :reason="declaration.validation.parking_other_price.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('construction_stages')"
              :style="getCellStyle('construction_stages')"
              :class="getCellClass('construction_stages', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position pre-line"
              data-column-name="construction_stages"
            >
              {{ getConstructionStages(declaration.construction_stages) }}
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.construction_stages
                "
                :reason="declaration.validation.construction_stages.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('purpose')"
              :style="getCellStyle('purpose')"
              :class="getCellClass('purpose', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="purpose"
            >
              {{ declaration.purpose }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes('purpose')
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="declaration.validation && declaration.validation.purpose"
                :reason="declaration.validation.purpose.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('floors_min')"
              :style="getCellStyle('floors_min')"
              :class="getCellClass('floors_min', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="floors_min"
            >
              {{ declaration.floors_min }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'floors_min'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.floors_min
                "
                :reason="declaration.validation.floors_min.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('floors_max')"
              :style="getCellStyle('floors_max')"
              :class="getCellClass('floors_max', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="floors_max"
            >
              {{ declaration.floors_max }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'floors_max'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.floors_max
                "
                :reason="declaration.validation.floors_max.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('wall_frame_material')"
              :style="getCellStyle('wall_frame_material')"
              :class="getCellClass('wall_frame_material', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="wall_frame_material"
            >
              {{ shortness(declaration.wall_frame_material, 80) }}
              <q-icon
                v-if="
                  declaration.wall_frame_material &&
                  declaration.wall_frame_material.length > 80
                "
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="cursor-pointer"
                @click="copyToBuffer(declaration.wall_frame_material)"
              >
                <q-tooltip max-width="20rem" :delay="500">
                  {{ declaration.wall_frame_material }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'wall_frame_material'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.wall_frame_material
                "
                :reason="declaration.validation.wall_frame_material.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('floor_material')"
              :style="getCellStyle('floor_material')"
              :class="getCellClass('floor_material', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="floor_material"
            >
              {{ shortness(declaration.floor_material, 80) }}
              <q-icon
                v-if="
                  declaration.floor_material &&
                  declaration.floor_material.length > 80
                "
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="cursor-pointer"
                @click="copyToBuffer(declaration.floor_material)"
              >
                <q-tooltip max-width="20rem" :delay="500">
                  {{ declaration.floor_material }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'floor_material'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.floor_material
                "
                :reason="declaration.validation.floor_material.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('construction_permit_number')"
              :style="getCellStyle('construction_permit_number')"
              :class="getCellClass('construction_permit_number', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="construction_permit_number"
            >
              {{ declaration.construction_permit_number }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'construction_permit_number'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.construction_permit_number
                "
                :reason="
                  declaration.validation.construction_permit_number.reason
                "
              />
            </div>

            <div
              v-if="visibleColumns.includes('construction_permit_issued_at')"
              :style="getCellStyle('construction_permit_issued_at')"
              :class="
                getCellClass('construction_permit_issued_at', declaration)
              "
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="construction_permit_issued_at"
            >
              {{ declaration.construction_permit_issued_at }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'construction_permit_issued_at'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.construction_permit_issued_at
                "
                :reason="
                  declaration.validation.construction_permit_issued_at.reason
                "
              />
            </div>

            <div
              v-if="visibleColumns.includes('construction_permit_valid_until')"
              :style="getCellStyle('construction_permit_valid_until')"
              :class="
                getCellClass('construction_permit_valid_until', declaration)
              "
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="construction_permit_valid_until"
            >
              {{ declaration.construction_permit_valid_until }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'construction_permit_valid_until'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.construction_permit_valid_until
                "
                :reason="
                  declaration.validation.construction_permit_valid_until.reason
                "
              />
            </div>

            <div
              v-if="
                visibleColumns.includes(
                  'construction_permit_valid_until_updated_at'
                )
              "
              :style="
                getCellStyle('construction_permit_valid_until_updated_at')
              "
              :class="
                getCellClass(
                  'construction_permit_valid_until_updated_at',
                  declaration
                )
              "
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="construction_permit_valid_until_updated_at"
            >
              {{ declaration.construction_permit_valid_until_updated_at }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'construction_permit_valid_until_updated_at'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation
                    .construction_permit_valid_until_updated_at
                "
                :reason="
                  declaration.validation
                    .construction_permit_valid_until_updated_at.reason
                "
              />
            </div>

            <div
              v-if="
                visibleColumns.includes('builder_rights_ground_valid_until')
              "
              :style="getCellStyle('builder_rights_ground_valid_until')"
              :class="
                getCellClass('builder_rights_ground_valid_until', declaration)
              "
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="builder_rights_ground_valid_until"
            >
              {{ declaration.builder_rights_ground_valid_until }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'builder_rights_ground_valid_until'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.builder_rights_ground_valid_until
                "
                :reason="
                  declaration.validation.builder_rights_ground_valid_until
                    .reason
                "
              />
            </div>

            <div
              v-if="
                visibleColumns.includes('builder_rights_contract_updated_at')
              "
              :style="getCellStyle('builder_rights_contract_updated_at')"
              :class="
                getCellClass('builder_rights_contract_updated_at', declaration)
              "
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="builder_rights_contract_updated_at"
            >
              {{ declaration.builder_rights_contract_updated_at }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'builder_rights_contract_updated_at'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.builder_rights_contract_updated_at
                "
                :reason="
                  declaration.validation.builder_rights_contract_updated_at
                    .reason
                "
              />
            </div>

            <div
              v-if="visibleColumns.includes('living_total_qty')"
              :style="getCellStyle('living_total_qty')"
              :class="getCellClass('living_total_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="living_total_qty"
            >
              {{ declaration.living_total_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'living_total_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.living_total_qty
                "
                :reason="declaration.validation.living_total_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_total_qty')"
              :style="getCellStyle('nonliving_total_qty')"
              :class="getCellClass('nonliving_total_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_total_qty"
            >
              {{ declaration.nonliving_total_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_total_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_total_qty
                "
                :reason="declaration.validation.nonliving_total_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_total_parking_qty')"
              :style="getCellStyle('nonliving_total_parking_qty')"
              :class="getCellClass('nonliving_total_parking_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_total_parking_qty"
            >
              {{ declaration.nonliving_total_parking_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_total_parking_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_total_parking_qty
                "
                :reason="
                  declaration.validation.nonliving_total_parking_qty.reason
                "
              />
            </div>

            <div
              v-if="visibleColumns.includes('nonliving_total_other_qty')"
              :style="getCellStyle('nonliving_total_other_qty')"
              :class="getCellClass('nonliving_total_other_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="nonliving_total_other_qty"
            >
              {{ declaration.nonliving_total_other_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'nonliving_total_other_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.nonliving_total_other_qty
                "
                :reason="
                  declaration.validation.nonliving_total_other_qty.reason
                "
              />
            </div>

            <div
              v-if="visibleColumns.includes('builder_obligations_securing')"
              :style="getCellStyle('builder_obligations_securing')"
              :class="getCellClass('builder_obligations_securing', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="builder_obligations_securing"
            >
              {{ declaration.builder_obligations_securing }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'builder_obligations_securing'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.builder_obligations_securing
                "
                :reason="
                  declaration.validation.builder_obligations_securing.reason
                "
              />
            </div>

            <div
              v-if="visibleColumns.includes('escrow_bank')"
              :style="getCellStyle('escrow_bank')"
              :class="getCellClass('escrow_bank', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="escrow_bank"
            >
              {{ declaration.escrow_bank }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'escrow_bank'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.escrow_bank
                "
                :reason="declaration.validation.escrow_bank.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('payment_contributions_fund')"
              :style="getCellStyle('payment_contributions_fund')"
              :class="getCellClass('payment_contributions_fund', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="payment_contributions_fund"
            >
              {{ declaration.payment_contributions_fund }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'payment_contributions_fund'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.payment_contributions_fund
                "
                :reason="
                  declaration.validation.payment_contributions_fund.reason
                "
              />
            </div>

            <div
              v-if="visibleColumns.includes('builder_raising_money')"
              :style="getCellStyle('builder_raising_money')"
              :class="getCellClass('builder_raising_money', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="builder_raising_money"
            >
              {{ declaration.builder_raising_money }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'builder_raising_money'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.builder_raising_money
                "
                :reason="declaration.validation.builder_raising_money.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('financing_bank')"
              :style="getCellStyle('financing_bank')"
              :class="getCellClass('financing_bank', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="financing_bank"
            >
              {{ declaration.financing_bank }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'financing_bank'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.financing_bank
                "
                :reason="declaration.validation.financing_bank.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('financing_credit_amount')"
              :style="getCellStyle('financing_credit_amount')"
              :class="getCellClass('financing_credit_amount', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="financing_credit_amount"
            >
              {{ rubF(declaration.financing_credit_amount) }}
              <q-icon
                v-if="declaration.financing_credit_amount"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.financing_credit_amount)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.financing_credit_amount) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'financing_credit_amount'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.financing_credit_amount
                "
                :reason="declaration.validation.financing_credit_amount.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('financing_credit_limit')"
              :style="getCellStyle('financing_credit_limit')"
              :class="getCellClass('financing_credit_limit', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="financing_credit_limit"
            >
              {{ rubF(declaration.financing_credit_limit) }}
              <q-icon
                v-if="declaration.financing_credit_limit"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.financing_credit_limit)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.financing_credit_limit) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'financing_credit_limit'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.financing_credit_limit
                "
                :reason="declaration.validation.financing_credit_limit.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('financing_credit_balance')"
              :style="getCellStyle('financing_credit_balance')"
              :class="getCellClass('financing_credit_balance', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="financing_credit_balance"
            >
              {{ rubF(declaration.financing_credit_balance) }}
              <q-icon
                v-if="declaration.financing_credit_balance"
                name="mdi-eye-outline"
                color="primary"
                size="xs"
                class="q-ml-xs cursor-pointer"
                @click="copyToBuffer(declaration.financing_credit_balance)"
              >
                <q-tooltip :delay="500">
                  {{ numF(declaration.financing_credit_balance) }}
                </q-tooltip>
              </q-icon>
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'financing_credit_balance'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.financing_credit_balance
                "
                :reason="declaration.validation.financing_credit_balance.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('fund_bank')"
              :style="getCellStyle('fund_bank')"
              :class="getCellClass('fund_bank', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="fund_bank"
            >
              {{ declaration.fund_bank }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'fund_bank'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation && declaration.validation.fund_bank
                "
                :reason="declaration.validation.fund_bank.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('buildings_qty')"
              :style="getCellStyle('buildings_qty')"
              :class="getCellClass('buildings_qty', declaration)"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="buildings_qty"
            >
              {{ declaration.buildings_qty }}
            </div>

            <div
              v-if="visibleColumns.includes('keys_initial_issue_at')"
              :style="getCellStyle('keys_initial_issue_at')"
              :class="getCellClass('keys_initial_issue_at', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="keys_initial_issue_at"
            >
              {{ declaration.keys_initial_issue_at }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'keys_initial_issue_at'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.keys_initial_issue_at
                "
                :reason="declaration.validation.keys_initial_issue_at.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('keys_planned_issue_at')"
              :style="getCellStyle('keys_planned_issue_at')"
              :class="getCellClass('keys_planned_issue_at', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="keys_planned_issue_at"
            >
              {{ declaration.keys_planned_issue_at }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'keys_planned_issue_at'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.keys_planned_issue_at
                "
                :reason="declaration.validation.keys_planned_issue_at.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('lift_qty')"
              :style="getCellStyle('lift_qty')"
              :class="getCellClass('lift_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="lift_qty"
            >
              {{ declaration.lift_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'lift_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="declaration.validation && declaration.validation.lift_qty"
                :reason="declaration.validation.lift_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('lift_cargo_qty')"
              :style="getCellStyle('lift_cargo_qty')"
              :class="getCellClass('lift_cargo_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="lift_cargo_qty"
            >
              {{ declaration.lift_cargo_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'lift_cargo_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.lift_cargo_qty
                "
                :reason="declaration.validation.lift_cargo_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('lift_cargo_passenger_qty')"
              :style="getCellStyle('lift_cargo_passenger_qty')"
              :class="getCellClass('lift_cargo_passenger_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="lift_cargo_passenger_qty"
            >
              {{ declaration.lift_cargo_passenger_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'lift_cargo_passenger_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.lift_cargo_passenger_qty
                "
                :reason="declaration.validation.lift_cargo_passenger_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('lift_wheelchair_qty')"
              :style="getCellStyle('lift_wheelchair_qty')"
              :class="getCellClass('lift_wheelchair_qty', declaration)"
              class="l-table__cell l-table__cell--bordered relative-position"
              data-column-name="lift_wheelchair_qty"
            >
              {{ declaration.lift_wheelchair_qty }}
              <q-icon
                v-if="
                  declaration.corrections_applied_for_fields.includes(
                    'lift_wheelchair_qty'
                  )
                "
                name="mdi-circle-edit-outline"
                class="absolute-top-right cursor-pointer"
                color="blue-7"
                size="xs"
                @click="showDeclarationAppliedCorrectionsDialog(declaration)"
              />
              <DeclarationCellAlertTooltip
                v-if="
                  declaration.validation &&
                  declaration.validation.lift_wheelchair_qty
                "
                :reason="declaration.validation.lift_wheelchair_qty.reason"
              />
            </div>

            <div
              v-if="visibleColumns.includes('rooms_info')"
              :style="getCellStyle('rooms_info')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="rooms_info"
            >
              <q-btn
                v-if="declaration.rooms_info"
                label="Смотреть данные"
                color="primary"
                @click="showDeclarationRoomsInfoDialogDialog(declaration)"
              />
            </div>

            <div
              v-if="visibleColumns.includes('created_at')"
              :style="getCellStyle('created_at')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="created_at"
            >
              {{ declaration.created_at }}
            </div>

            <div
              v-if="visibleColumns.includes('updated_at')"
              :style="getCellStyle('updated_at')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="updated_at"
            >
              {{ declaration.updated_at }}
            </div>

            <div
              v-if="visibleColumns.includes('actions')"
              :style="getCellStyle('actions')"
              class="l-table__cell l-table__cell--bordered"
              data-column-name="actions"
            >
              <div class="flex no-wrap">
                <q-btn
                  v-if="+$can(['declaration.delete'])"
                  :disable="
                    awaitDeleting ||
                    declarationUpdating.includes(declaration.id)
                  "
                  flat
                  icon="mdi-delete-outline"
                  title="Удалить декларацию"
                  @click="deleteDeclaration(declaration.id)"
                />
              </div>
            </div>

            <q-inner-loading
              :showing="declarationUpdating.includes(declaration.id)"
              style="background-color: transparent"
            >
              <q-skeleton type="rect" class="full-width full-height" />
            </q-inner-loading>
          </div>
        </div>
      </div>

      <div
        v-if="
          !declarations ||
          (Array.isArray(declarations) && declarations.length === 0)
        "
        class="q-pa-md"
      >
        Нет данных
      </div>

      <div
        v-if="declarations && declarations.length"
        class="col-24 flex justify-end items-center q-px-md q-py-sm bg-white sticky-bottom shadow-up-3"
        style="z-index: 2"
      >
        <div class="text-body3 q-mr-md">
          Всего: <span class="text-body1">{{ pagination.rowsNumber }}</span>
        </div>

        <div class="row no-wrap items-center" style="height: 25px">
          <q-select
            v-model="pagination.rowsPerPage"
            :disable="loading"
            :options="[10, 25, 50]"
            borderless
            @update:model-value="onRowsPerPageInput"
          >
            <template v-slot:before>
              <q-icon name="mdi-eye-outline" size="sm" />
            </template>
          </q-select>
        </div>

        <q-pagination
          v-model="pagination.page"
          :disable="loading"
          :input="true"
          :max="Math.ceil(pagination.rowsNumber / pagination.rowsPerPage)"
          :model-value="pagination.page"
          @update:model-value="onPaginationInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import lf from "@/plugins/localforage";
  import emitter from "@/plugins/mitt";
  import {
    boxBellColor,
    boxBellIconName,
    customSort,
    deleteKeysWithEmptyValues,
    getDomRfBuildingLink,
    getFormattedLocalDateTimeString,
    groupBy,
    normalizeQueryForRequest,
    unidecode,
    uniencode,
  } from "@/utils/batch";
  import { rubFormat, numberFormat } from "@/utils/numberFormatter";
  import { shortness } from "@/plugins/filters";
  import { markRaw } from "vue";
  import { createMetaMixin, exportFile, copyToClipboard } from "quasar";
  import DeclarationInfoDialog from "@/components/dialogs/DeclarationInfoDialog";
  import DeclarationTableFiltersRow from "@/components/declarations/DeclarationTableFiltersRow";
  import DeclarationCellAlertTooltip from "@/components/declarations/DeclarationCellAlertTooltip";
  import DeclarationCorrectionAddDialog from "@/components/dialogs/DeclarationCorrectionAddDialog";
  import DeclarationAppliedCorrectionsDialog from "@/components/dialogs/DeclarationAppliedCorrectionsDialog";
  import DeclarationHistoryDialog from "@/components/dialogs/DeclarationHistoryDialog";
  import DeclarationValidationAlertsDialog from "@/components/dialogs/DeclarationValidationAlertsDialog";
  import DeclarationTableColumnOrderDialog from "@/components/dialogs/DeclarationTableColumnOrderDialog";
  import DeclarationEditCadastralsDialog from "@/components/dialogs/DeclarationEditCadastralsDialog";
  import DeclarationEditConstructionStagesDialog from "@/components/dialogs/DeclarationEditConstructionStagesDialog";
  import DeclarationEditNumericFieldDialog from "@/components/dialogs/DeclarationEditNumericFieldDialog";
  import DeclarationEditStringFieldDialog from "@/components/dialogs/DeclarationEditStringFieldDialog";
  import DeclarationEditDateFieldDialog from "@/components/dialogs/DeclarationEditDateFieldDialog";
  import DeclarationReParseDialog from "@/components/dialogs/DeclarationReParseDialog";
  import DeclarationRoomsInfoDialog from "@/components/dialogs/DeclarationRoomsInfoDialog.vue";

  import {
    columns as decColumns,
    defaultVisibleColumns,
  } from "@/utils/declarations";
  import cloneDeep from "lodash.clonedeep";

  export default {
    name: "DeclarationList",

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Декларации",
        };
      }),
    ],

    components: {
      DeclarationCellAlertTooltip,
      DeclarationTableFiltersRow,
    },

    created() {
      emitter.on(
        "declaration-applied-corrections-rollbacked",
        this.setDeclarationsAndStats
      );
      emitter.on("declarations-change-column-order", this.onChangeColumnOrder);
      emitter.on("declaration-updated", this.setDeclarationsAndStats);
      window.addEventListener("resize", this.getStylesForDeclarationsTable);
    },

    destroyed() {
      window.removeEventListener("resize", this.getStylesForDeclarationsTable);
    },

    async mounted() {
      if (this.$route.query.page) {
        this.pagination.page = Number(this.$route.query.page);
      }

      if (this.$store.state.declarationSearch) {
        await this.$router.replace({
          query: {
            search: this.$store.state.declarationSearch,
          },
        });
      }

      if (this.$route.query.search) {
        const search = unidecode(this.$route.query.search);

        Object.keys(search).forEach((key) => {
          this.search[key] = search[key];
        });
      }

      const pdFieldNames = this.pdFields.map((f) => f.name);

      this.columns.forEach((column, index) => {
        if (column.label) {
          return;
        }

        if (pdFieldNames.includes(column.name)) {
          let pdFieldColumn = this.pdFields.filter(
            (f) => f.name === column.name
          )[0];
          this.columns[index].data_type = pdFieldColumn.data_type;
          this.columns[index].name_db = pdFieldColumn.name;
          this.columns[index].label = pdFieldColumn.name_readable;
          this.columns[index].pd_title = pdFieldColumn.pd_title;
          this.columns[index].pd_article = pdFieldColumn.pd_article;
          this.columns[index].tooltip_note = this.getColumnTooltipText(
            this.columns[index]
          );
        }
      });

      // using map-options for q-select slows down performance for large data-sets
      const columnsForSelect = this.columns.map((c) => ({
        label: c.label,
        name: c.name,
        pd_article: c.pd_article,
      }));
      this.columnsForSelect = markRaw(columnsForSelect);

      await this.checkVisibleColumns();
      await this.syncColumnSettings();
      await this.updateColumnOrderByUserSettings();
      this.sortColumnsByOrder();
      this.updateColumnStyles(this.columns);
      await this.loadRowsPerPageParamFromStorage();

      const [resGrades, resApProjects] = await Promise.all([
        api.declaration.grades(),
        api.apProject.find({
          q: {},
          transformer: "ApProjectShortTransformer",
        }),
      ]);

      this.declarationGrades = resGrades.data;
      this.boxMarks = cloneDeep(this.$store.state.boxMarks);
      this.boxMarks.push({ name: "Не указано*", id: null }); // extra option to filter all unidentified
      this.apProjects = customSort(resApProjects.data, "name");
      this.apProjectsData = customSort(resApProjects.data, "name");

      this.$nextTick(() => {
        this.getStylesForDeclarationsTable();
      });

      this.loading = false;
    },

    unmounted() {
      emitter.off(
        "declaration-applied-corrections-rollbacked",
        this.setDeclarationsAndStats
      );
      emitter.off("declarations-change-column-order", this.onChangeColumnOrder);
      emitter.off("declaration-updated", this.setDeclarationsAndStats);
    },

    computed: {
      pdFields() {
        return this.$store.state.pdFields;
      },

      pdFieldsCorrectable() {
        return this.$store.state.pdFields.filter((f) => f.correctable);
      },

      pdFieldsEditableNames() {
        return this.$store.state.pdFields
          .filter((f) => f.editable)
          .map((i) => i.name);
      },

      validationFilterOptions() {
        return [
          { label: "Любые", value: null },
          { label: "С ошибками", value: "with-errors" },
          { label: "Без ошибок", value: "without-errors" },
        ];
      },

      relatedFilterOptions() {
        return [
          { label: "Не важно", value: null },
          { label: "Нет", value: "negative" },
          { label: "Есть", value: "positive" },
        ];
      },
    },

    data() {
      return {
        loading: true,
        awaitDeleting: false,
        awaitDeclarations: false,
        declarationUpdating: [],
        awaitExportExcel: false,
        declarations: [],
        stats: null,
        declarationGrades: [],
        boxMarks: [],
        apProjects: [],
        // dialog_rooms_info: false,
        pagination: {
          rowsNumber: null,
          rowsPerPage: 10,
          sort: {
            published_at: true,
            box_serial: true,
          },
          page: 1,
        },
        rowContextMenuData: null,
        fieldContextMenuShowed: false,
        columnsForSelect: [],
        columns: markRaw(decColumns),
        visibleColumns: defaultVisibleColumns,
        search: {
          id: { c: "eq", v: null },
          box_region_name: [],
          box_serial: { c: "eq", v: null },
          box_mark_ids: [],
          box_bell_ids: [],
          ap_box_serial: { c: "ctn", v: null },
          ap_project_serials: [],
          box_name: { c: "ctn", v: null },
          declaration_grade_ids: [],
          pd_number: { c: "ctn", v: null },
          box_pd_order: { c: "eq", v: null },
          published_at: {
            from: null,
            to: null,
          },
          builder_inn: { c: "ctn", v: null },
          general_contractors: null,
          company: { c: "ctn", v: null },
          reported_at: {
            from: null,
            to: null,
          },
          profit_net: { c: "gte", v: null },
          accounts_payable: { c: "gte", v: null },
          accounts_receivable: { c: "gte", v: null },
          object_square: { c: "gte", v: null },
          living_square: { c: "gte", v: null },
          nonliving_square: { c: "gte", v: null },
          total_square: { c: "gte", v: null },
          construction_cost: { c: "gte", v: null },
          living_escrow_qty: { c: "gte", v: null },
          living_fund_qty: { c: "gte", v: null },
          living_other_qty: { c: "gte", v: null },
          nonliving_escrow_qty: { c: "gte", v: null },
          nonliving_fund_qty: { c: "gte", v: null },
          nonliving_other_qty: { c: "gte", v: null },
          parking_escrow_qty: { c: "gte", v: null },
          parking_fund_qty: { c: "gte", v: null },
          parking_other_qty: { c: "gte", v: null },
          living_escrow_square: { c: "gte", v: null },
          living_fund_square: { c: "gte", v: null },
          living_other_square: { c: "gte", v: null },
          nonliving_escrow_square: { c: "gte", v: null },
          nonliving_fund_square: { c: "gte", v: null },
          nonliving_other_square: { c: "gte", v: null },
          parking_escrow_square: { c: "gte", v: null },
          parking_fund_square: { c: "gte", v: null },
          parking_other_square: { c: "gte", v: null },
          living_escrow_price: { c: "gte", v: null },
          living_fund_price: { c: "gte", v: null },
          living_other_price: { c: "gte", v: null },
          nonliving_escrow_price: { c: "gte", v: null },
          nonliving_fund_price: { c: "gte", v: null },
          nonliving_other_price: { c: "gte", v: null },
          parking_escrow_price: { c: "gte", v: null },
          parking_fund_price: { c: "gte", v: null },
          parking_other_price: { c: "gte", v: null },
          purpose: { c: "ctn", v: null },
          floors_min: { c: "gte", v: null },
          floors_max: { c: "gte", v: null },
          wall_frame_material: { c: "ctn", v: null },
          floor_material: { c: "ctn", v: null },
          construction_permit_number: { c: "ctn", v: null },
          construction_permit_issued_at: {
            from: null,
            to: null,
          },
          construction_permit_valid_until: {
            from: null,
            to: null,
          },
          construction_permit_valid_until_updated_at: {
            from: null,
            to: null,
          },
          builder_rights_ground_valid_until: {
            from: null,
            to: null,
          },
          builder_rights_contract_updated_at: {
            from: null,
            to: null,
          },
          living_total_qty: { c: "gte", v: null },
          nonliving_total_qty: { c: "gte", v: null },
          nonliving_total_parking_qty: { c: "gte", v: null },
          nonliving_total_other_qty: { c: "gte", v: null },
          builder_obligations_securing: [],
          escrow_bank: { c: "ctn", v: null },
          payment_contributions_fund: [],
          builder_raising_money: [],
          financing_bank: { c: "ctn", v: null },
          financing_credit_amount: { c: "gte", v: null },
          financing_credit_limit: { c: "gte", v: null },
          financing_credit_balance: { c: "gte", v: null },
          fund_bank: { c: "ctn", v: null },
          buildings_qty: { c: "gte", v: null },
          keys_initial_issue_at: {
            from: null,
            to: null,
          },
          keys_planned_issue_at: {
            from: null,
            to: null,
          },
          lift_qty: { c: "gte", v: null },
          lift_cargo_qty: { c: "gte", v: null },
          lift_cargo_passenger_qty: { c: "gte", v: null },
          lift_wheelchair_qty: { c: "gte", v: null },
          created_at: {
            from: null,
            to: null,
          },
          updated_at: {
            from: null,
            to: null,
          },
          validation: null,
          only_actual: false,
          box_skip: null,
          related: null,
        },
        cellStyles: {},
        tableStyles: "",
      };
    },

    methods: {
      async filterDeclarations() {
        await this.updateQuery(true);
        await this.setDeclarationsAndStats(true);
        await this.getStylesForDeclarationsTable();
      },

      async setDeclarationsAndStats(isFiltering = false) {
        this.awaitDeclarations = true;

        // we can't send filtering request from page greater then 1
        if (isFiltering) {
          this.pagination.page = 1;
        }

        await this.loadRowsPerPageParamFromStorage();

        let paginateOptions = {
          q: normalizeQueryForRequest(this.search),
          sort_by: this.pagination.sort,
          descending: this.pagination.descending,
          limit: this.pagination.rowsPerPage,
          page: this.pagination.page,
        };

        const include = [
          "box.ap_project",
          "box.box_mark",
          "box.box_bell",
          "box.ap_boxes",
          "pd_file",
          "declaration_grade",
          "declaration_applied_corrections.creator",
        ];

        await Promise.all([
          api.declaration.find(paginateOptions, include.join(",")),
          api.declaration.stats(),
        ])
          .then((res) => {
            if (res[0].status === 200 && res[0].data.declarations) {
              for (const index in res[0].data.declarations) {
                res[0].data.declarations[index].corrections_applied_for_fields =
                  this.getFieldNamesForAppliedCorrections(
                    res[0].data.declarations[index]
                  );
              }

              this.declarations = res[0].data.declarations;
              this.pagination.rowsNumber = res[0].data.meta.pagination.total;
            }

            if (res[0].status === 204) {
              this.declarations = [];
            }

            this.stats = res[1].data;
          })
          .then(() => {
            this.awaitDeclarations = false;
            this.getStylesForDeclarationsTable();
          });
      },

      async setStats() {
        const res = await api.declaration.stats();

        this.stats = res.data;
      },

      async onSaveDeclarationGrade(declaration, grade) {
        const index = this.declarations.indexOf(declaration);
        const include = [
          "box.ap_project",
          "box.box_mark",
          "box.box_bell",
          "box.ap_boxes",
          "pd_file",
          "declaration_grade",
          "declaration_applied_corrections.creator",
        ];

        this.declarationUpdating.push(declaration.id);

        await api.declaration
          .updateGrade(declaration.id, grade.id, include.join(","))
          .then(
            async (res) => {
              if (index !== -1) {
                res.data.declaration.corrections_applied_for_fields =
                  this.getFieldNamesForAppliedCorrections(res.data.declaration);
                this.declarations[index] = res.data.declaration;
              }

              await this.setStats();
            },
            (error) => {
              if (error.response.status === 422) {
                this.$q.notify({
                  color: "negative",
                  message: error.response.data.message,
                  timeout: 3500,
                });

                if (index !== -1) {
                  this.declarations[index] = error.response.data.declaration;
                }
              }
            }
          );

        this.declarationUpdating = this.declarationUpdating.filter(
          (id) => id !== declaration.id
        );
      },

      getFieldNamesForAppliedCorrections(declaration) {
        if (!declaration.declaration_applied_corrections) {
          return [];
        }

        return declaration.declaration_applied_corrections.map((i) => i.field);
      },

      async setGradeForDeclaration(declaration, gradeAlias) {
        this.declarationUpdating.push(declaration.id);

        const grade = this.declarationGrades.filter(
          (g) => g.alias === gradeAlias
        )[0];
        await this.onSaveDeclarationGrade(declaration, grade);

        this.declarationUpdating = this.declarationUpdating.filter(
          (id) => id !== declaration.id
        );
      },

      showDeclarationEditFieldDialog(declaration) {
        let component;

        // special fields, complex types
        switch (this.rowContextMenuData.columnName) {
          case "cadastrals":
            component = DeclarationEditCadastralsDialog;
            break;
          case "construction_stages":
            component = DeclarationEditConstructionStagesDialog;
            break;
        }

        const column = this.columns.filter(
          (i) => i.name === this.rowContextMenuData.columnName
        )[0];

        // common fields, basic types
        switch (column.data_type) {
          case "numeric":
            component = DeclarationEditNumericFieldDialog;
            break;
          case "string":
            component = DeclarationEditStringFieldDialog;
            break;
          case "date":
            component = DeclarationEditDateFieldDialog;
            break;
        }

        if (!component) {
          throw new Error(
            `field "${this.rowContextMenuData.columnName}" doesnt have dialog component now`
          );
        }

        this.$q.dialog({
          component,
          componentProps: {
            declaration,
            columnName: this.rowContextMenuData.columnName,
            fieldValue: this.rowContextMenuData.fieldValue,
            columnLabel: column.label,
          },
        });
      },

      showDeclarationCorrectionAddDialog(declaration) {
        this.$q.dialog({
          component: DeclarationCorrectionAddDialog,
          componentProps: {
            declaration: declaration,
            columnName: this.rowContextMenuData.columnName,
            fieldValue: this.rowContextMenuData.fieldValue,
          },
        });
      },

      showDeclarationRoomsInfoDialogDialog(declaration) {
        this.$q.dialog({
          component: DeclarationRoomsInfoDialog,
          componentProps: {
            declaration: declaration,
          },
        });
      },

      showDeclarationAppliedCorrectionsDialog(declaration) {
        if (!declaration.declaration_applied_corrections) {
          return;
        }

        let grouped = groupBy(
          declaration.declaration_applied_corrections,
          (v) => v.field
        );

        this.$q.dialog({
          component: DeclarationAppliedCorrectionsDialog,
          componentProps: {
            declarationId: declaration.id,
            fields: grouped,
          },
        });
      },

      showDeclarationHistoryDialog(declaration) {
        this.$q.dialog({
          component: DeclarationHistoryDialog,
          componentProps: {
            declarationId: declaration.id,
          },
        });
      },

      showDeclarationValidationAlertsDialog(declaration) {
        this.$q.dialog({
          component: DeclarationValidationAlertsDialog,
          componentProps: {
            declarationId: declaration.id,
            validation: declaration.validation,
          },
        });
      },

      onShowDeclarationRowContextMenu(evt, declaration) {
        let dataset = evt.target.dataset.columnName
          ? evt.target.dataset
          : evt.target.parentElement.dataset;

        if (!dataset.columnName) {
          // get higher node but max 2 deeper
          dataset = evt.target.parentElement.parentElement.dataset;
        }

        this.rowContextMenuData = {
          declarationId: declaration.id,
          columnName: dataset.columnName,
          fieldValue: declaration[dataset.columnName],
        };
      },

      makeDefaultColumnsVisible() {
        this.visibleColumns = defaultVisibleColumns;
        this.onInputVisibleColumns(
          JSON.parse(JSON.stringify(this.visibleColumns))
        );
      },

      makeAllColumnsVisible() {
        this.visibleColumns = this.columnsForSelect.map((i) => i.name);
        this.onInputVisibleColumns(
          JSON.parse(JSON.stringify(this.visibleColumns))
        );
      },

      onInputVisibleColumns(values) {
        this.updateColumnStyles(this.columns);
        this.saveVisibleColumnsInStorage(values);
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style + `order:${item.order};`;
        });

        this.cellStyles = obj;
      },

      async syncColumnSettings() {
        const userColumnNames =
          (await lf.getItem("declarations_column_order")) || [];
        const columnNames = this.columns.map((i) => i.name);

        if (
          userColumnNames.length !== columnNames.length ||
          columnNames.some((name) => !userColumnNames.includes(name))
        ) {
          // replace user settings by default columns order
          await lf.setItem(
            "declarations_column_order",
            this.columns.map((item) => item.name)
          );
        }
      },

      async updateColumnOrderByUserSettings() {
        const userColumnNames = await lf.getItem("declarations_column_order");

        if (!userColumnNames) {
          return;
        }

        this.columns.forEach((item, index) => {
          const foundIndex = userColumnNames.findIndex(
            (colName) => colName === item.name
          );

          if (foundIndex === -1) {
            throw new Error(
              `can not find "${item.name}" column in user's column settings`
            );
          }

          this.columns[index].order = foundIndex;
        });
      },

      async checkVisibleColumns() {
        let visibleColumns = await lf.getItem(
          "declarations_table_visible_columns"
        );

        if (visibleColumns) {
          const columnNames = this.columns.map((item) => item.name);

          // remove old column names
          visibleColumns.forEach((name, index) => {
            if (!columnNames.includes(name)) {
              delete visibleColumns[index];
            }
          });

          this.visibleColumns = visibleColumns;
          await this.saveVisibleColumnsInStorage(visibleColumns);
        }
      },

      async saveVisibleColumnsInStorage(values) {
        try {
          await lf.setItem("declarations_table_visible_columns", values);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }
      },

      showDeclarationInfoDialog() {
        this.$q.dialog({
          component: DeclarationInfoDialog,
        });
      },

      showDeclarationTableColumnOrderDialog() {
        this.$q.dialog({
          component: DeclarationTableColumnOrderDialog,
          componentProps: {
            columns: this.columns,
          },
        });
      },

      showDeclarationReParseDialog(id) {
        this.$q.dialog({
          component: DeclarationReParseDialog,
          componentProps: { id },
        });
      },

      async onPaginationInput(page) {
        // this.pagination.page = page;
        await this.updateQuery(false);
        await this.setDeclarationsAndStats();

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },

      async loadRowsPerPageParamFromStorage() {
        let rowsPerPage = await lf.getItem("declarations_table_rows_per_page");

        if (rowsPerPage && rowsPerPage !== this.pagination.rowsPerPage) {
          this.pagination.rowsPerPage = rowsPerPage;
        }
      },

      async onRowsPerPageInput(val) {
        try {
          await lf.setItem("declarations_table_rows_per_page", val);
        } catch (e) {
          this.$q.notify({
            color: "negative",
            message:
              "Хранилище браузера недоступно. Пожалуйста, проверьте настройки.",
            timeout: 60000,
          });
        }

        await this.setDeclarationsAndStats();
      },

      sortByColumn(field) {
        this.pagination.sort[field] = !this.pagination.sort[field];
        this.setDeclarationsAndStats();
      },

      deleteDeclaration(id) {
        this.$q
          .dialog({
            title: `Удаление декларации ID: ${id}`,
            message: "Вы уверены?",
            ok: {
              label: "Удалить",
            },
          })
          .onOk(async () => {
            this.awaitDeleting = true;

            await api.declaration
              .delete(id)
              .then(
                (res) => {
                  this.setDeclarationsAndStats();
                },
                (error) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.message,
                  });
                }
              )
              .then(() => {
                this.awaitDeleting = false;
              });
          });
      },

      exportExcel() {
        this.$q
          .dialog({
            title: "Выгрузка деклараций",
            message: "Название выгрузки",
            prompt: {
              model:
                "Выгрузка деклараций от " + getFormattedLocalDateTimeString(),
              type: "text",
            },
            cancel: true,
            persistent: true,
          })
          .onOk(async (data) => {
            let params = {
              q: normalizeQueryForRequest(this.search),
              sort_by: this.pagination.sortBy,
              descending: this.pagination.descending,
              page: 1,
              include: "box.ap_project,box.box_mark,pd_file,declaration_grade",
              columns: this.columns.filter((c) =>
                this.visibleColumns.includes(c.name)
              ),
              title: data,
              rows_qty: this.pagination.rowsNumber,
            };

            this.awaitExportExcel = true;

            api.declaration
              .exportExcel(params)
              .then((res) => {
                if (res.status === 201) {
                  let enc = new TextDecoder("utf-8");

                  this.$q.notify({
                    color: "positive",
                    message: JSON.parse(enc.decode(res.data)).message,
                  });
                } else {
                  const fileName = res.headers["content-disposition"].slice(21);

                  let blob = new Blob([res.data], {
                    type: res.headers["content-type"],
                  });

                  exportFile(fileName, blob);
                }
              })
              .then(() => {
                this.awaitExportExcel = false;
              });
          });
      },

      getColumnTooltipText(column) {
        let text = "";

        if (column.pd_article) {
          if (Array.isArray(column.pd_article)) {
            text += column.pd_article.join(", ") + ` ${column.pd_title}`;
          } else {
            text += `${column.pd_article} ${column.pd_title}`;
          }
        }

        if (column.name_db) {
          text += ` (${column.name_db})`;
        }

        return text.trim();
      },

      shortness(value, length) {
        return shortness(value, length);
      },

      getCadastrals(cadastrals) {
        if (!cadastrals) {
          return "";
        }

        let text = "";

        cadastrals.forEach((i, index) => {
          text += `${index + 1}) ${i.number} (S: ${i.square})\n`;
        });

        return text;
      },

      getGeneralContractors(data) {
        if (!data) {
          return "";
        }

        let text = "";

        data.forEach((i, index) => {
          text += `${index + 1}) ${i.contractor} (ИНН: ${i.inn})\n`;
        });

        return text;
      },
      isIterable(obj) {
        // checks for null and undefined
        if (obj == null) {
          return false;
        }
        return typeof obj[Symbol.iterator] === "function";
      },

      getConstructionStages(stages) {
        if (!stages || !this.isIterable(stages)) {
          return "";
        }

        let text = "";

        stages.forEach((i) => {
          text += `${i.ending_at} ${i.percent}%\n`;
        });

        return text;
      },

      getCellClass(field, declaration) {
        if (!(declaration.validation && declaration.validation[field])) {
          return "";
        }

        switch (declaration.validation[field].status) {
          case 9:
            return "l-table__cell--negative";
          case 6:
            return "l-table__cell--warning";
          default:
            return "";
        }
      },

      getDeclarationRowClasses(declaration) {
        let classes = [];

        if (
          this.rowContextMenuData &&
          this.rowContextMenuData.declarationId === declaration.id
        ) {
          classes.push("l-table__row--active");
        }

        if (declaration.is_actual) {
          classes.push("l-table__row--marked-a");
        }

        if (
          declaration.validation === null ||
          (Array.isArray(declaration.validation) &&
            declaration.validation.length === 0)
        ) {
          return classes.join(" ");
        }

        classes.push("l-table__row--negative");

        return classes.join(" ");
      },

      getStylesForDeclarationsTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        if (this.$refs.topBox) {
          let height =
            this.$store.state.windowInnerHeight -
            (this.$refs.topBox.offsetHeight + 138);
          this.tableStyles = `max-height: ${height}px!important`;
        }
      },

      getBuildingLink(serial) {
        return getDomRfBuildingLink(serial);
      },

      numF(val) {
        return numberFormat(val);
      },

      rubF(val) {
        return rubFormat(val);
      },

      copyToBuffer(val) {
        copyToClipboard(val)
          .then(() => {
            this.$q.notify({
              color: "positive",
              message: "Скопировано в буфер!",
            });
          })
          .catch(() => {
            this.$q.notify({
              color: "negative",
              message: "Копирование в буфер не выполнено",
            });
          });
      },

      onChangeColumnOrder(payload) {
        this.sortColumnsByOrder(); // sort to show actual order in visibleColumns select
        this.updateColumnStyles(payload.columns);
      },

      sortColumnsByOrder() {
        customSort(this.columns, "order");
      },

      async updateQuery(filtering) {
        let search = normalizeQueryForRequest(this.search);
        deleteKeysWithEmptyValues(search);
        this.$store.commit("SET_DECLARATION_SEARCH", uniencode(search));

        await this.$router.replace({
          query: {
            search: this.$store.state.declarationSearch,
            page: filtering ? 1 : this.pagination.page,
          },
        });
      },

      async onUpdateDeclarationField(declaration, fieldName, data) {
        this.declarationUpdating.push(declaration.id);

        api.declaration
          .updateField(declaration.id, fieldName, data)
          .then(
            (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });

              this.setDeclarationsAndStats();

              if (["box_ap_project"].includes(fieldName)) {
                emitter.emit("refresh-declaration-filters");
              }
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });

              if (error.response.status === 422) {
                Object.keys(error.response.data.errors).forEach((name, i) => {
                  this.$q.notify({
                    color: "negative",
                    message: error.response.data.errors[name][i],
                    timeout: 10000,
                  });
                });
              }
            }
          )
          .then(() => {
            this.declarationUpdating = this.declarationUpdating.filter(
              (id) => id !== declaration.id
            );
          });
      },

      filterApProjects(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.apProjects = this.apProjectsData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      updateDynamicFilters() {
        emitter.emit("declarations-update-dynamic-filters");
      },

      getBoxBellColor(alias) {
        return boxBellColor(alias);
      },

      getBoxBellIconName(alias) {
        return boxBellIconName(alias);
      },
    },
  };
</script>
