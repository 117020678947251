<template>
  <q-dialog
    ref="dialog"
    position="top"
    transition-hide="slide-up"
    transition-show="slide-down"
    v-if="declaration.rooms_info"
  >
    <q-card style="min-width: 910px">
      <q-card-section class="row items-right q-pb-none">
        <div class="text-h6">Данные о квартирах</div>
        <q-space />
        <q-btn icon="mdi-close" flat round dense v-close-popup />
      </q-card-section>
      <q-card-section>
        <q-markup-table dense>
          <thead>
            <tr style="font-size: 10px">
              <td>Условный<br />номер</td>
              <td>Назначение</td>
              <td>Этаж <br />расположения</td>
              <td>Номер<br />подъезда</td>
              <td>Общая<br />площадь (кв.м)</td>
              <td>Количество<br />комнат</td>
              <td>Общая жилая<br />площадь (кв.м)</td>
              <td>Высота<br />потолков (м)</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rooms_info in declaration.rooms_info.living">
              <td>{{ rooms_info["Условный номер"] }}</td>
              <td>{{ rooms_info["Назначение"] }}</td>
              <td>{{ rooms_info["Этаж расположения"] }}</td>
              <td>{{ rooms_info["Номер подъезда"] }}</td>
              <td>{{ rooms_info["Общая площадь (кв.м)"] }}</td>
              <td>{{ rooms_info["Количество комнат"] }}</td>
              <td>
                {{ rooms_info["Общая жилая площадь (кв.м)"] }}
              </td>
              <td>{{ rooms_info["Высота потолков (м)"] }}</td>
            </tr>
          </tbody>
        </q-markup-table>
        <br />
        <q-markup-table dense>
          <thead>
            <tr style="font-size: 10px">
              <td>Условный<br />номер</td>
              <td>Назначение</td>
              <td>Этаж <br />расположения</td>
              <td>Номер<br />подъезда</td>
              <td>Площадь (кв.м)</td>
              <td>Наименование помещения</td>
              <td>Площадь (кв.м) (2)</td>
              <td>Высота<br />потолков (м)</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rooms_info in declaration.rooms_info.nonliving">
              <td>{{ rooms_info["Условный номер"] }}</td>
              <td>{{ rooms_info["Назначение"] }}</td>
              <td>{{ rooms_info["Этаж расположения"] }}</td>
              <td>{{ rooms_info["Номер подъезда"] }}</td>
              <td>{{ rooms_info["Площадь (кв.м)"] }}</td>
              <td>{{ rooms_info["Наименование помещения"] }}</td>
              <td>
                {{ rooms_info["Площадь (кв.м) (2)"] }}
              </td>
              <td>{{ rooms_info["Высота потолков (м)"] }}</td>
            </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
  export default {
    name: "DeclarationRoomsInfoDialog",

    emits: ["hide"],

    props: {
      declaration: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        awaitResponse: false,
        selectionMode: "selected",
      };
    },

    methods: {
      // following method is REQUIRED
      // (don't change its name --> "show")
      show() {
        this.$refs.dialog.show();
      },

      // following method is REQUIRED
      // (don't change its name --> "hide")
      hide() {
        this.$refs.dialog.hide();
      },

      onDialogHide() {
        this.$emit("hide");
      },
    },
  };
</script>
