import auth from "@/router/middlewares/auth";
import checkPermissions from "@/router/middlewares/checkPermissions";

import ApBoxes from "@/pages/apBoxes/ApBoxes";
import ApBoxList from "@/pages/apBoxes/ApBoxList";
import UnsoldPremises from "@/pages/apBoxes/UnsoldPremises";

const apBoxRoutes = [
  {
    path: "/ap-boxes",
    component: ApBoxes,
    beforeEnter: [auth, checkPermissions],
    children: [
      {
        path: "",
        component: ApBoxList,
        name: "ApBoxList",
        meta: {
          layout: "main",
          permissions: ["ap-box.view"],
          crumbs: "/АП корпуса",
        },
      },
      {
        path: "unsold-premises",
        component: UnsoldPremises,
        name: "UnsoldPremises",
        meta: {
          layout: "main",
          permissions: ["ap-box.unsold-premises.view"],
          crumbs: "/АП корпуса/Нереализованные помещения",
        },
      },
    ],
  },
];

export default apBoxRoutes;
