<template>
  <div class="l-table__row min-w-full" role="rowgroup">
    <div
      v-if="visibleColumns.includes('id')"
      :style="getCellStyle('id')"
      class="l-table__cell"
    >
      <q-input
        v-model.number="search.id.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.id.c"
            :options="compareNumberOptions"
            :value="search.id.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('box_region_name')"
      :style="getCellStyle('box_region_name')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.box_region_name"
        :options="regionNameOptions"
        options-dense
        use-chips
        multiple
        dense
        @update:model-value="onSearchBoxRegionName"
      />
    </div>

    <div
      v-if="visibleColumns.includes('box_serial')"
      :style="getCellStyle('box_serial')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.box_serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.box_serial.c"
            :options="compareStringOptions"
            :value="search.box_serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('box_mark')"
      :style="getCellStyle('box_mark')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.box_mark_ids"
        :options="boxMarks"
        options-dense
        use-chips
        multiple
        dense
        map-options
        emit-value
        option-label="name"
        option-value="id"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('ap_box_serials')"
      :style="getCellStyle('ap_box_serials')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.ap_box_serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.ap_box_serial.c"
            :options="compareStringOptions"
            :value="search.ap_box_serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('box_ap_project')"
      :style="getCellStyle('box_ap_project')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.ap_project_serials"
        :options="boxApProjectsOptions"
        options-dense
        use-chips
        multiple
        dense
        map-options
        emit-value
        option-label="name"
        option-value="serial"
        use-input
        @filter="filterApProjects"
        @update:model-value="onSearchInput"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>{{ scope.opt.name }}</q-item-label>
              <q-item-label caption>{{ scope.opt.serial }}</q-item-label>
              <q-item-label caption>{{ scope.opt.region_name }} </q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>

    <div
      v-if="visibleColumns.includes('box_name')"
      :style="getCellStyle('box_name')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.box_name.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.box_name.c"
            :options="compareStringOptions"
            :value="search.box_name.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('declaration_grade')"
      :style="getCellStyle('declaration_grade')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.declaration_grade_ids"
        :options="declarationGrades"
        dense
        use-chips
        map-options
        multiple
        emit-value
        option-label="name"
        option-value="id"
        @update:model-value="onSearchInput"
      >
        <template v-slot:option="scope">
          <q-item v-bind="scope.itemProps">
            <q-item-section>
              <q-item-label>{{ scope.opt.name }}</q-item-label>
            </q-item-section>
            <q-item-section side>
              <q-icon
                v-if="scope.opt.alias === 'valid'"
                color="green"
                name="mdi-shield-check"
                size="sm"
              />
              <q-icon
                v-if="scope.opt.alias === 'attention'"
                color="orange"
                name="mdi-alert-rhombus"
                size="sm"
              />
            </q-item-section>
          </q-item>
        </template>
      </q-select>
    </div>

    <div
      v-if="visibleColumns.includes('pd_file_cdn_url')"
      :style="getCellStyle('pd_file_cdn_url')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('pd_file_external_url_inline')"
      :style="getCellStyle('pd_file_external_url_inline')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('pd_number')"
      :style="getCellStyle('pd_number')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.pd_number.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.pd_number.c"
            :options="compareStringOptions"
            :value="search.pd_number.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('box_pd_order')"
      :style="getCellStyle('box_pd_order')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.box_pd_order.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.box_pd_order.c"
            :options="compareNumberOptions"
            :value="search.box_pd_order.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('published_at')"
      :style="getCellStyle('published_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.published_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.published_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.published_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerPublishedFrom">
                <q-date
                  v-model="search.published_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerPublishedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.published_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.published_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.published_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerPublishedTo">
                <q-date
                  v-model="search.published_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerPublishedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('builder_inn')"
      :style="getCellStyle('builder_inn')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.builder_inn.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.builder_inn.c"
            :options="compareStringOptions"
            :value="search.builder_inn.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('general_contractors')"
      :style="getCellStyle('general_contractors')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('company')"
      :style="getCellStyle('company')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.company.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.company.c"
            :options="compareStringOptions"
            :value="search.company.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('reported_at')"
      :style="getCellStyle('reported_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.reported_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.reported_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.reported_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerReportedFrom">
                <q-date
                  v-model="search.reported_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerReportedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.reported_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.reported_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.reported_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerReportedTo">
                <q-date
                  v-model="search.reported_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerReportedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('profit_net')"
      :style="getCellStyle('profit_net')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.profit_net.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.profit_net.c"
            :options="compareNumberOptions"
            :value="search.profit_net.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('accounts_payable')"
      :style="getCellStyle('accounts_payable')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.accounts_payable.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.accounts_payable.c"
            :options="compareNumberOptions"
            :value="search.accounts_payable.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('accounts_receivable')"
      :style="getCellStyle('accounts_receivable')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.accounts_receivable.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.accounts_receivable.c"
            :options="compareNumberOptions"
            :value="search.accounts_receivable.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('object_square')"
      :style="getCellStyle('object_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.object_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.object_square.c"
            :options="compareNumberOptions"
            :value="search.object_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_square')"
      :style="getCellStyle('living_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_square.c"
            :options="compareNumberOptions"
            :value="search.living_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_square')"
      :style="getCellStyle('nonliving_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_square.c"
            :options="compareNumberOptions"
            :value="search.nonliving_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('total_square')"
      :style="getCellStyle('total_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.total_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.total_square.c"
            :options="compareNumberOptions"
            :value="search.total_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('cadastrals')"
      :style="getCellStyle('cadastrals')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('construction_cost')"
      :style="getCellStyle('construction_cost')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.construction_cost.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.construction_cost.c"
            :options="compareNumberOptions"
            :value="search.construction_cost.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_escrow_qty')"
      :style="getCellStyle('living_escrow_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_escrow_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_escrow_qty.c"
            :options="compareNumberOptions"
            :value="search.living_escrow_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_fund_qty')"
      :style="getCellStyle('living_fund_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_fund_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_fund_qty.c"
            :options="compareNumberOptions"
            :value="search.living_fund_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_other_qty')"
      :style="getCellStyle('living_other_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_other_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_other_qty.c"
            :options="compareNumberOptions"
            :value="search.living_other_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_escrow_qty')"
      :style="getCellStyle('nonliving_escrow_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_escrow_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_escrow_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_escrow_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_fund_qty')"
      :style="getCellStyle('nonliving_fund_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_fund_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_fund_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_fund_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_other_qty')"
      :style="getCellStyle('nonliving_other_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_other_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_other_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_other_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_escrow_qty')"
      :style="getCellStyle('parking_escrow_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_escrow_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_escrow_qty.c"
            :options="compareNumberOptions"
            :value="search.parking_escrow_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_fund_qty')"
      :style="getCellStyle('parking_fund_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_fund_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_fund_qty.c"
            :options="compareNumberOptions"
            :value="search.parking_fund_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_other_qty')"
      :style="getCellStyle('parking_other_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_other_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_other_qty.c"
            :options="compareNumberOptions"
            :value="search.parking_other_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_escrow_square')"
      :style="getCellStyle('living_escrow_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_escrow_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_escrow_square.c"
            :options="compareNumberOptions"
            :value="search.living_escrow_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_fund_square')"
      :style="getCellStyle('living_fund_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_fund_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_fund_square.c"
            :options="compareNumberOptions"
            :value="search.living_fund_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_other_square')"
      :style="getCellStyle('living_other_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_other_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_other_square.c"
            :options="compareNumberOptions"
            :value="search.living_other_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_escrow_square')"
      :style="getCellStyle('nonliving_escrow_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_escrow_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_escrow_square.c"
            :options="compareNumberOptions"
            :value="search.nonliving_escrow_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_fund_square')"
      :style="getCellStyle('nonliving_fund_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_fund_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_fund_square.c"
            :options="compareNumberOptions"
            :value="search.nonliving_fund_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_other_square')"
      :style="getCellStyle('nonliving_other_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_other_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_other_square.c"
            :options="compareNumberOptions"
            :value="search.nonliving_other_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_escrow_square')"
      :style="getCellStyle('parking_escrow_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_escrow_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_escrow_square.c"
            :options="compareNumberOptions"
            :value="search.parking_escrow_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_fund_square')"
      :style="getCellStyle('parking_fund_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_fund_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_fund_square.c"
            :options="compareNumberOptions"
            :value="search.parking_fund_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_other_square')"
      :style="getCellStyle('parking_other_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_other_square.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_other_square.c"
            :options="compareNumberOptions"
            :value="search.parking_other_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_escrow_price')"
      :style="getCellStyle('living_escrow_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_escrow_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_escrow_price.c"
            :options="compareNumberOptions"
            :value="search.living_escrow_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_fund_price')"
      :style="getCellStyle('living_fund_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_fund_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_fund_price.c"
            :options="compareNumberOptions"
            :value="search.living_fund_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('living_other_price')"
      :style="getCellStyle('living_other_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_other_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_other_price.c"
            :options="compareNumberOptions"
            :value="search.living_other_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_escrow_price')"
      :style="getCellStyle('nonliving_escrow_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_escrow_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_escrow_price.c"
            :options="compareNumberOptions"
            :value="search.nonliving_escrow_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_fund_price')"
      :style="getCellStyle('nonliving_fund_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_fund_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_fund_price.c"
            :options="compareNumberOptions"
            :value="search.nonliving_fund_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_other_price')"
      :style="getCellStyle('nonliving_other_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_other_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_other_price.c"
            :options="compareNumberOptions"
            :value="search.nonliving_other_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_escrow_price')"
      :style="getCellStyle('parking_escrow_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_escrow_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_escrow_price.c"
            :options="compareNumberOptions"
            :value="search.parking_escrow_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_fund_price')"
      :style="getCellStyle('parking_fund_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_fund_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_fund_price.c"
            :options="compareNumberOptions"
            :value="search.parking_fund_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_other_price')"
      :style="getCellStyle('parking_other_price')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_other_price.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_other_price.c"
            :options="compareNumberOptions"
            :value="search.parking_other_price.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('construction_stages')"
      :style="getCellStyle('construction_stages')"
      class="l-table__cell"
    />

    <div
      v-if="visibleColumns.includes('purpose')"
      :style="getCellStyle('purpose')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.purpose.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.purpose.c"
            :options="compareStringOptions"
            :value="search.purpose.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('floors_min')"
      :style="getCellStyle('floors_min')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.floors_min.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.floors_min.c"
            :options="compareNumberOptions"
            :value="search.floors_min.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('floors_max')"
      :style="getCellStyle('floors_max')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.floors_max.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.floors_max.c"
            :options="compareNumberOptions"
            :value="search.floors_max.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('wall_frame_material')"
      :style="getCellStyle('wall_frame_material')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.wall_frame_material.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.wall_frame_material.c"
            :options="compareStringOptions"
            :value="search.wall_frame_material.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('floor_material')"
      :style="getCellStyle('floor_material')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.floor_material.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.floor_material.c"
            :options="compareStringOptions"
            :value="search.floor_material.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('construction_permit_number')"
      :style="getCellStyle('construction_permit_number')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.construction_permit_number.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.construction_permit_number.c"
            :options="compareStringOptions"
            :value="search.construction_permit_number.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('construction_permit_issued_at')"
      :style="getCellStyle('construction_permit_issued_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.construction_permit_issued_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.construction_permit_issued_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.construction_permit_issued_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerConstructionPermitIssuedFrom">
                <q-date
                  v-model="search.construction_permit_issued_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerConstructionPermitIssuedFrom.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.construction_permit_issued_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.construction_permit_issued_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.construction_permit_issued_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerConstructionPermitIssuedTo">
                <q-date
                  v-model="search.construction_permit_issued_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerConstructionPermitIssuedTo.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('construction_permit_valid_until')"
      :style="getCellStyle('construction_permit_valid_until')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.construction_permit_valid_until.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.construction_permit_valid_until.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.construction_permit_valid_until.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerConstructionPermitValidUntilFrom">
                <q-date
                  v-model="search.construction_permit_valid_until.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerConstructionPermitValidUntilFrom.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.construction_permit_valid_until.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.construction_permit_valid_until.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.construction_permit_valid_until.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerConstructionPermitValidUntilTo">
                <q-date
                  v-model="search.construction_permit_valid_until.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerConstructionPermitValidUntilTo.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="
        visibleColumns.includes('construction_permit_valid_until_updated_at')
      "
      :style="getCellStyle('construction_permit_valid_until_updated_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.construction_permit_valid_until_updated_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.construction_permit_valid_until_updated_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="
                search.construction_permit_valid_until_updated_at.from = null
              "
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy
                ref="datePickerConstructionPermitValidUntilUpdatedFrom"
              >
                <q-date
                  v-model="
                    search.construction_permit_valid_until_updated_at.from
                  "
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerConstructionPermitValidUntilUpdatedFrom.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.construction_permit_valid_until_updated_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.construction_permit_valid_until_updated_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="
                search.construction_permit_valid_until_updated_at.to = null
              "
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy
                ref="datePickerConstructionPermitValidUntilUpdatedTo"
              >
                <q-date
                  v-model="search.construction_permit_valid_until_updated_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerConstructionPermitValidUntilUpdatedTo.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('builder_rights_ground_valid_until')"
      :style="getCellStyle('builder_rights_ground_valid_until')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.builder_rights_ground_valid_until.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.builder_rights_ground_valid_until.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.builder_rights_ground_valid_until.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerBuilderRightsGroundValidUntilFrom">
                <q-date
                  v-model="search.builder_rights_ground_valid_until.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerBuilderRightsGroundValidUntilFrom.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.builder_rights_ground_valid_until.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.builder_rights_ground_valid_until.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.builder_rights_ground_valid_until.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerBuilderRightsGroundValidUntilTo">
                <q-date
                  v-model="search.builder_rights_ground_valid_until.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerBuilderRightsGroundValidUntilTo.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('builder_rights_contract_updated_at')"
      :style="getCellStyle('builder_rights_contract_updated_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.builder_rights_contract_updated_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.builder_rights_contract_updated_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.builder_rights_contract_updated_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerBuilderRightsContractUpdatedFrom">
                <q-date
                  v-model="search.builder_rights_contract_updated_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerBuilderRightsContractUpdatedFrom.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.builder_rights_contract_updated_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.builder_rights_contract_updated_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.builder_rights_contract_updated_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerBuilderRightsContractUpdatedTo">
                <q-date
                  v-model="search.builder_rights_contract_updated_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="
                    $refs.datePickerBuilderRightsContractUpdatedTo.hide()
                  "
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('living_total_qty')"
      :style="getCellStyle('living_total_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_total_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_total_qty.c"
            :options="compareNumberOptions"
            :value="search.living_total_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_total_qty')"
      :style="getCellStyle('nonliving_total_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_total_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_total_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_total_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_total_parking_qty')"
      :style="getCellStyle('nonliving_total_parking_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_total_parking_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_total_parking_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_total_parking_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('nonliving_total_other_qty')"
      :style="getCellStyle('nonliving_total_other_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.nonliving_total_other_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.nonliving_total_other_qty.c"
            :options="compareNumberOptions"
            :value="search.nonliving_total_other_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('builder_obligations_securing')"
      :style="getCellStyle('builder_obligations_securing')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.builder_obligations_securing"
        :options="builderObligationsSecuringOptions"
        :option-label="(item) => (item === null ? '-' : item)"
        options-dense
        use-chips
        multiple
        dense
        @update:model-value="
          () => {
            onSearchInput();
            setDynamicFilters();
          }
        "
      />
    </div>

    <div
      v-if="visibleColumns.includes('escrow_bank')"
      :style="getCellStyle('escrow_bank')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.escrow_bank.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.escrow_bank.c"
            :options="compareStringOptions"
            :value="search.escrow_bank.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('payment_contributions_fund')"
      :style="getCellStyle('payment_contributions_fund')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.payment_contributions_fund"
        :options="paymentContributionsFundOptions"
        :option-label="(item) => (item === null ? '-' : item)"
        options-dense
        use-chips
        multiple
        dense
        @update:model-value="
          () => {
            onSearchInput();
            setDynamicFilters();
          }
        "
      />
    </div>

    <div
      v-if="visibleColumns.includes('builder_raising_money')"
      :style="getCellStyle('builder_raising_money')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.builder_raising_money"
        :options="builderRaisingMoneyOptions"
        :option-label="(item) => (item === null ? '-' : item)"
        options-dense
        use-chips
        multiple
        dense
        @update:model-value="
          () => {
            onSearchInput();
            setDynamicFilters();
          }
        "
      />
    </div>

    <div
      v-if="visibleColumns.includes('financing_bank')"
      :style="getCellStyle('financing_bank')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.financing_bank.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.financing_bank.c"
            :options="compareStringOptions"
            :value="search.financing_bank.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('financing_credit_amount')"
      :style="getCellStyle('financing_credit_amount')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.financing_credit_amount.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.financing_credit_amount.c"
            :options="compareNumberOptions"
            :value="search.financing_credit_amount.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('financing_credit_limit')"
      :style="getCellStyle('financing_credit_limit')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.financing_credit_limit.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.financing_credit_limit.c"
            :options="compareNumberOptions"
            :value="search.financing_credit_limit.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('financing_credit_balance')"
      :style="getCellStyle('financing_credit_balance')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.financing_credit_balance.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.financing_credit_balance.c"
            :options="compareNumberOptions"
            :value="search.financing_credit_balance.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('fund_bank')"
      :style="getCellStyle('fund_bank')"
      class="l-table__cell"
    >
      <q-input
        v-model.trim="search.fund_bank.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.fund_bank.c"
            :options="compareStringOptions"
            :value="search.fund_bank.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('buildings_qty')"
      :style="getCellStyle('buildings_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.buildings_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.buildings_qty.c"
            :options="compareNumberOptions"
            :value="search.buildings_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('keys_initial_issue_at')"
      :style="getCellStyle('keys_initial_issue_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.keys_initial_issue_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.keys_initial_issue_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.keys_initial_issue_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerKeysInitialIssueFrom">
                <q-date
                  v-model="search.keys_initial_issue_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerKeysInitialIssueFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.keys_initial_issue_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.keys_initial_issue_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.keys_initial_issue_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerKeysInitialIssueTo">
                <q-date
                  v-model="search.keys_initial_issue_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerKeysInitialIssueTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('keys_planned_issue_at')"
      :style="getCellStyle('keys_planned_issue_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.keys_planned_issue_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.keys_planned_issue_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.keys_planned_issue_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerKeysPlannedIssueFrom">
                <q-date
                  v-model="search.keys_planned_issue_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerKeysPlannedIssueFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.keys_planned_issue_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.keys_planned_issue_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.keys_planned_issue_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerKeysPlannedIssueTo">
                <q-date
                  v-model="search.keys_planned_issue_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerKeysPlannedIssueTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('lift_qty')"
      :style="getCellStyle('lift_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.lift_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.lift_qty.c"
            :options="compareNumberOptions"
            :value="search.lift_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('lift_cargo_qty')"
      :style="getCellStyle('lift_cargo_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.lift_cargo_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.lift_cargo_qty.c"
            :options="compareNumberOptions"
            :value="search.lift_cargo_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('lift_cargo_passenger_qty')"
      :style="getCellStyle('lift_cargo_passenger_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.lift_cargo_passenger_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.lift_cargo_passenger_qty.c"
            :options="compareNumberOptions"
            :value="search.lift_cargo_passenger_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('lift_wheelchair_qty')"
      :style="getCellStyle('lift_wheelchair_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.lift_wheelchair_qty.v"
        class="appearance-none"
        type="number"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.lift_wheelchair_qty.c"
            :options="compareNumberOptions"
            :value="search.lift_wheelchair_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('rooms_info')"
      :style="getCellStyle('rooms_info')"
      class="l-table__cell"
    ></div>

    <div
      v-if="visibleColumns.includes('created_at')"
      :style="getCellStyle('created_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedFrom">
                <q-date
                  v-model="search.created_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.created_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.created_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.created_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerCreatedTo">
                <q-date
                  v-model="search.created_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerCreatedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('updated_at')"
      :style="getCellStyle('updated_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.updated_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.updated_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.updated_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerUpdatedFrom">
                <q-date
                  v-model="search.updated_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerUpdatedFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.updated_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.updated_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.updated_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerUpdatedTo">
                <q-date
                  v-model="search.updated_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerUpdatedTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('actions')"
      :style="getCellStyle('actions')"
      class="l-table__cell"
    />
  </div>
</template>

<script>
  import ComparisonSelect from "@/components/ComparisonSelect";
  import {
    COMPARE_NUMBER_OPTIONS,
    COMPARE_STRING_OPTIONS,
    SEARCH_SELECT_PROPS,
    SEARCH_INPUT_PROPS,
    normalizeQueryForRequest,
  } from "@/utils/batch";
  import api from "@/api";
  import emitter from "@/plugins/mitt";

  export default {
    name: "DeclarationTableFiltersRow",

    emits: ["filter-declarations", "set-declarations"],

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      visibleColumns: {
        type: Array,
        required: true,
      },
      declarationGrades: {
        type: Array,
        required: true,
      },
      boxMarks: {
        type: Array,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
    },

    components: {
      ComparisonSelect,
    },

    data() {
      return {
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        compareNumberOptions: COMPARE_NUMBER_OPTIONS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
        regionNameOptions: [],
        builderRaisingMoneyOptions: [],
        builderObligationsSecuringOptions: [],
        paymentContributionsFundOptions: [],
        boxApProjectsOptions: [],
      };
    },

    watch: {
      "search.published_at.from"() {
        this.$emit("filter-declarations");
      },
      "search.published_at.to"() {
        this.$emit("filter-declarations");
      },
      "search.reported_at.from"() {
        this.$emit("filter-declarations");
      },
      "search.reported_at.to"() {
        this.$emit("filter-declarations");
      },
      "search.construction_permit_issued_at.from"() {
        this.$emit("filter-declarations");
      },
      "search.construction_permit_issued_at.to"() {
        this.$emit("filter-declarations");
      },
      "search.construction_permit_valid_until.from"() {
        this.$emit("filter-declarations");
      },
      "search.construction_permit_valid_until.to"() {
        this.$emit("filter-declarations");
      },
      "search.construction_permit_valid_until_updated_at.from"() {
        this.$emit("filter-declarations");
      },
      "search.construction_permit_valid_until_updated_at.to"() {
        this.$emit("filter-declarations");
      },
      "search.builder_rights_ground_valid_until.from"() {
        this.$emit("filter-declarations");
      },
      "search.builder_rights_ground_valid_until.to"() {
        this.$emit("filter-declarations");
      },
      "search.builder_rights_contract_updated_at.from"() {
        this.$emit("filter-declarations");
      },
      "search.builder_rights_contract_updated_at.to"() {
        this.$emit("filter-declarations");
      },
      "search.keys_initial_issue_at.from"() {
        this.$emit("filter-declarations");
      },
      "search.keys_initial_issue_at.to"() {
        this.$emit("filter-declarations");
      },
      "search.keys_planned_issue_at.from"() {
        this.$emit("filter-declarations");
      },
      "search.keys_planned_issue_at.to"() {
        this.$emit("filter-declarations");
      },
      "search.created_at.from"() {
        this.$emit("filter-declarations");
      },
      "search.created_at.to"() {
        this.$emit("filter-declarations");
      },
      "search.updated_at.from"() {
        this.$emit("filter-declarations");
      },
      "search.updated_at.to"() {
        this.$emit("filter-declarations");
      },
    },

    created() {
      emitter.on("refresh-declaration-filters", this.setFilters);
      emitter.on("declarations-update-dynamic-filters", this.setDynamicFilters);
    },

    mounted() {
      this.setFilters();
      this.setDynamicFilters();

      this.$emit("set-declarations");
    },

    unmounted() {
      emitter.off("refresh-declaration-filters", this.setFilters);
      emitter.off(
        "declarations-update-dynamic-filters",
        this.setDynamicFilters
      );
    },

    methods: {
      async setFilters() {
        const res = await api.declaration.filters(
          normalizeQueryForRequest(this.search)
        );

        this.regionNameOptions = res.data.region_names.sort();
        this.boxApProjectsOptions = res.data.ap_projects.sort();
        // not reactive for filtering
        this.boxApProjectsOptionsData = res.data.ap_projects.sort();
      },

      async setDynamicFilters() {
        const fields = [
          "builder_raising_money",
          "builder_obligations_securing",
          "payment_contributions_fund",
        ];
        let search = normalizeQueryForRequest(this.search);
        let promises = [];

        fields.forEach((field) => {
          promises.push(api.declaration.dynamicFilter(field, search));
        });

        const results = await Promise.all(promises);

        console.log(results);

        this.builderRaisingMoneyOptions =
          results[0].data.builder_raising_money.sort();
        this.builderObligationsSecuringOptions =
          results[1].data.builder_obligations_securing.sort();
        this.paymentContributionsFundOptions =
          results[2].data.payment_contributions_fund.sort();
      },

      onSearchBoxRegionName(val) {
        this.setFilters();
        this.onSearchInput(val);
      },

      onSearchInput(val) {
        this.$emit("filter-declarations");
      },

      onSearchCompareInput(val) {
        this.$emit("filter-declarations");
      },

      filterApProjects(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.boxApProjectsOptions = this.boxApProjectsOptionsData.filter(
            (i) => i.name.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },
    },
  };
</script>
