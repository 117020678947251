export const columns = [
  {
    name: "id",
    label: "ID",
    sortName: "id",
    style: "width: 80px;",
    order: 1,
  },
  {
    name: "box_region_name",
    label: "Регион",
    sortName: "box_region_name",
    style: "width: 160px;",
    order: 2,
  },
  {
    name: "box_serial",
    label: "ДомРФ корпус",
    sortName: "box_serial",
    style: "width: 100px;",
    order: 3,
  },
  {
    name: "box_mark",
    label: "Статус ДомРФ корпуса",
    style: "width: 140px;",
    order: 4,
  },
  {
    name: "ap_box_serials",
    label: "АП корпуса",
    sortName: "ap_box_serials",
    style: "width: 100px;",
    order: 5,
  },
  {
    name: "box_ap_project",
    label: "АП проект",
    style: "width: 200px;",
    order: 6,
  },
  {
    name: "box_name",
    label: "Название корпуса",
    sortName: "box_name",
    style: "width: 200px;",
    order: 7,
  },
  {
    name: "declaration_grade",
    label: "Состояние",
    sortName: "declaration_grade",
    style: "width: 170px;",
    order: 8,
  },
  {
    name: "pd_file_cdn_url",
    label: "Файл CDN",
    style: "width: 80px;",
    order: 9,
  },
  {
    name: "pd_file_external_url_inline",
    label: "Файл домрф",
    style: "width: 80px;",
    order: 10,
  },
  {
    name: "pd_number",
    sortName: "pd_number",
    style: "width: 120px;",
    order: 11,
  },
  {
    name: "box_pd_order",
    label: "Номер объекта в ПД",
    style: "width: 80px;",
    order: 12,
  },
  {
    name: "published_at",
    sortName: "published_at",
    style: "width: 140px;",
    order: 13,
  },
  {
    name: "builder_inn",
    sortName: "builder_inn",
    style: "width: 100px;",
    order: 14,
  },
  {
    name: "general_contractors",
    sortName: "general_contractors",
    style: "width: 240px;",
    order: 15,
  },
  {
    name: "company",
    sortName: "company",
    style: "width: 240px;",
    order: 16,
  },
  {
    name: "reported_at",
    sortName: "reported_at",
    style: "width: 140px;",
    order: 17,
  },
  {
    name: "profit_net",
    sortName: "profit_net",
    style: "width: 140px;",
    order: 18,
  },
  {
    name: "accounts_payable",
    sortName: "accounts_payable",
    style: "width: 140px;",
    order: 19,
  },
  {
    name: "accounts_receivable",
    sortName: "accounts_receivable",
    style: "width: 140px;",
    order: 20,
  },
  {
    name: "object_square",
    sortName: "object_square",
    style: "width: 100px;",
    order: 21,
  },
  {
    name: "living_square",
    sortName: "living_square",
    style: "width: 100px;",
    order: 22,
  },
  {
    name: "nonliving_square",
    sortName: "nonliving_square",
    style: "width: 100px;",
    order: 23,
  },
  {
    name: "total_square",
    sortName: "total_square",
    style: "width: 100px;",
    order: 24,
  },
  {
    name: "cadastrals",
    style: "width: 200px;",
    order: 25,
  },
  {
    name: "construction_cost",
    sortName: "construction_cost",
    style: "width: 140px;",
    order: 26,
  },
  {
    name: "living_escrow_qty",
    sortName: "living_escrow_qty",
    style: "width: 80px;",
    order: 27,
  },
  {
    name: "living_fund_qty",
    sortName: "living_fund_qty",
    style: "width: 80px;",
    order: 28,
  },
  {
    name: "living_other_qty",
    sortName: "living_other_qty",
    style: "width: 80px;",
    order: 29,
  },
  {
    name: "nonliving_escrow_qty",
    sortName: "nonliving_escrow_qty",
    style: "width: 80px;",
    order: 30,
  },
  {
    name: "nonliving_fund_qty",
    sortName: "nonliving_fund_qty",
    style: "width: 80px;",
    order: 31,
  },
  {
    name: "nonliving_other_qty",
    sortName: "nonliving_other_qty",
    style: "width: 80px;",
    order: 32,
  },
  {
    name: "parking_escrow_qty",
    sortName: "parking_escrow_qty",
    style: "width: 80px;",
    order: 33,
  },
  {
    name: "parking_fund_qty",
    sortName: "parking_fund_qty",
    style: "width: 80px;",
    order: 34,
  },
  {
    name: "parking_other_qty",
    sortName: "parking_other_qty",
    style: "width: 80px;",
    order: 35,
  },
  {
    name: "living_escrow_square",
    sortName: "living_escrow_square",
    style: "width: 100px;",
    order: 36,
  },
  {
    name: "living_fund_square",
    sortName: "living_fund_square",
    style: "width: 100px;",
    order: 37,
  },
  {
    name: "living_other_square",
    sortName: "living_other_square",
    style: "width: 100px;",
    order: 38,
  },
  {
    name: "nonliving_escrow_square",
    sortName: "nonliving_escrow_square",
    style: "width: 100px;",
    order: 39,
  },
  {
    name: "nonliving_fund_square",
    sortName: "nonliving_fund_square",
    style: "width: 100px;",
    order: 40,
  },
  {
    name: "nonliving_other_square",
    sortName: "nonliving_other_square",
    style: "width: 100px;",
    order: 41,
  },
  {
    name: "parking_escrow_square",
    sortName: "parking_escrow_square",
    style: "width: 100px;",
    order: 42,
  },
  {
    name: "parking_fund_square",
    sortName: "parking_fund_square",
    style: "width: 100px;",
    order: 43,
  },
  {
    name: "parking_other_square",
    sortName: "parking_other_square",
    style: "width: 100px;",
    order: 44,
  },
  {
    name: "living_escrow_price",
    sortName: "living_escrow_price",
    style: "width: 120px;",
    order: 45,
  },
  {
    name: "living_fund_price",
    sortName: "living_fund_price",
    style: "width: 120px;",
    order: 46,
  },
  {
    name: "living_other_price",
    sortName: "living_other_price",
    style: "width: 120px;",
    order: 47,
  },
  {
    name: "nonliving_escrow_price",
    sortName: "nonliving_escrow_price",
    style: "width: 120px;",
    order: 48,
  },
  {
    name: "nonliving_fund_price",
    sortName: "nonliving_fund_price",
    style: "width: 120px;",
    order: 49,
  },
  {
    name: "nonliving_other_price",
    sortName: "nonliving_other_price",
    style: "width: 120px;",
    order: 50,
  },
  {
    name: "parking_escrow_price",
    sortName: "parking_escrow_price",
    style: "width: 120px;",
    order: 51,
  },
  {
    name: "parking_fund_price",
    sortName: "parking_fund_price",
    style: "width: 120px;",
    order: 52,
  },
  {
    name: "parking_other_price",
    sortName: "parking_other_price",
    style: "width: 120px;",
    order: 53,
  },
  {
    name: "construction_stages",
    style: "width: 160px;",
    order: 54,
  },
  {
    name: "purpose",
    sortName: "purpose",
    style: "width: 160px;",
    order: 55,
  },
  {
    name: "floors_min",
    sortName: "floors_min",
    style: "width: 80px;",
    order: 56,
  },
  {
    name: "floors_max",
    sortName: "floors_max",
    style: "width: 80px;",
    order: 57,
  },
  {
    name: "wall_frame_material",
    style: "width: 200px;",
    order: 58,
  },
  {
    name: "floor_material",
    style: "width: 200px;",
    order: 59,
  },
  {
    name: "construction_permit_number",
    sortName: "construction_permit_number",
    style: "width: 160px;",
    order: 60,
  },
  {
    name: "construction_permit_issued_at",
    sortName: "construction_permit_issued_at",
    style: "width: 140px;",
    order: 61,
  },
  {
    name: "construction_permit_valid_until",
    sortName: "construction_permit_valid_until",
    style: "width: 140px;",
    order: 62,
  },
  {
    name: "construction_permit_valid_until_updated_at",
    sortName: "construction_permit_valid_until_updated_at",
    style: "width: 140px;",
    order: 63,
  },
  {
    name: "builder_rights_ground_valid_until",
    sortName: "builder_rights_ground_valid_until",
    style: "width: 140px;",
    order: 64,
  },
  {
    name: "builder_rights_contract_updated_at",
    sortName: "builder_rights_contract_updated_at",
    style: "width: 140px;",
    order: 65,
  },
  {
    name: "living_total_qty",
    sortName: "living_total_qty",
    style: "width: 80px;",
    order: 66,
  },
  {
    name: "nonliving_total_qty",
    sortName: "nonliving_total_qty",
    style: "width: 80px;",
    order: 67,
  },
  {
    name: "nonliving_total_parking_qty",
    sortName: "nonliving_total_parking_qty",
    style: "width: 80px;",
    order: 68,
  },
  {
    name: "nonliving_total_other_qty",
    sortName: "nonliving_total_other_qty",
    style: "width: 80px;",
    order: 69,
  },
  {
    name: "builder_obligations_securing",
    sortName: "builder_obligations_securing",
    style: "width: 160px;",
    order: 70,
  },
  {
    name: "escrow_bank",
    sortName: "escrow_bank",
    style: "width: 160px;",
    order: 71,
  },
  {
    name: "payment_contributions_fund",
    sortName: "payment_contributions_fund",
    style: "width: 160px;",
    order: 72,
  },
  {
    name: "builder_raising_money",
    sortName: "builder_raising_money",
    style: "width: 160px;",
    order: 73,
  },
  {
    name: "financing_bank",
    sortName: "financing_bank",
    style: "width: 160px;",
    order: 74,
  },
  {
    name: "financing_credit_amount",
    sortName: "financing_credit_amount",
    style: "width: 120px;",
    order: 75,
  },
  {
    name: "financing_credit_limit",
    sortName: "financing_credit_limit",
    style: "width: 120px;",
    order: 76,
  },
  {
    name: "financing_credit_balance",
    sortName: "financing_credit_balance",
    style: "width: 120px;",
    order: 77,
  },
  {
    name: "fund_bank",
    sortName: "fund_bank",
    style: "width: 200px;",
    order: 78,
  },
  {
    name: "buildings_qty",
    sortName: "buildings_qty",
    style: "width: 80px;",
    order: 79,
  },
  {
    name: "keys_initial_issue_at",
    sortName: "keys_initial_issue_at",
    style: "width: 160px;",
    order: 80,
  },
  {
    name: "keys_planned_issue_at",
    sortName: "keys_planned_issue_at",
    style: "width: 160px;",
    order: 81,
  },
  {
    name: "lift_qty",
    sortName: "lift_qty",
    style: "width: 80px;",
    order: 82,
  },
  {
    name: "lift_cargo_qty",
    sortName: "lift_cargo_qty",
    style: "width: 80px;",
    order: 83,
  },
  {
    name: "lift_cargo_passenger_qty",
    sortName: "lift_cargo_passenger_qty",
    style: "width: 80px;",
    order: 84,
  },
  {
    name: "lift_wheelchair_qty",
    sortName: "keys_planned_issue_at",
    style: "width: 80px;",
    order: 85,
  },
  {
    name: "rooms_info",
    sortName: "rooms_info",
    style: "width: 130px;",
    order: 86,
  },
  {
    name: "created_at",
    label: "Создана",
    sortName: "created_at",
    style: "width: 140px;",
    order: 87,
  },
  {
    name: "updated_at",
    label: "Обновлена",
    sortName: "updated_at",
    style: "width: 140px;",
    order: 88,
  },
  {
    name: "actions",
    label: "Действия",
    style: "width: 80px;",
    order: 89,
  },
];

export const defaultVisibleColumns = [
  "id",
  "box_region_name",
  "box_serial",
  "ap_box_serials",
  "box_name",
  "declaration_grade",
  "pd_file_cdn_url",
  "pd_number",
  "box_pd_order",
  "published_at",
  "living_square",
  "nonliving_square",
  "total_square",
  "cadastrals",
  "living_total_qty",
  "construction_stages",
  "keys_planned_issue_at",
  "builder_obligations_securing",
  "living_escrow_qty",
  "living_fund_qty",
  "living_other_qty",
  "created_at",
  "updated_at",
  "actions",
];
