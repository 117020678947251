<template>
  <div v-if="!loading" class="col bg-white rounded-borders shadow-2">
    <div style="height: 2px">
      <q-linear-progress v-show="awaitRows" indeterminate size="2px" />
    </div>

    <div class="row bg-white">
      <div class="col-24 q-px-md">
        <div
          aria-label="Нереализованные помещения"
          class="l-table q-pb-sm"
          role="table"
          :style="getStylesForTable()"
        >
          <div class="l-table__row min-w-full sticky-top bg-white">
            <template v-for="item in columns" :key="item.name">
              <div
                :style="getCellStyle(item.name)"
                class="l-table__cell"
                role="columnheader"
              >
                <div>
                  {{ item.label }}
                </div>
              </div>
            </template>
          </div>

          <div
            v-for="row in rows"
            :key="row.id"
            class="l-table__row min-w-full"
          >
            <div class="l-table__row min-w-full" role="rowgroup">
              <div
                :style="getCellStyle('region_name')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.region_name }}
              </div>

              <div
                :style="getCellStyle('ap_project_name')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ap_project_name }}
              </div>

              <div
                :style="getCellStyle('ap_project_serial')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ap_project_serial }}
              </div>

              <div
                :style="getCellStyle('ap_box_serial')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ap_box_serial }}
              </div>

              <div
                :style="getCellStyle('pbo_qty')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ups.pbo_qty }}
              </div>

              <div
                :style="getCellStyle('unsold_qty_rosreestr')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ups.unsold_qty_rosreestr }}
              </div>

              <div
                :style="getCellStyle('last_pd_issue_date_before_rve')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ups.last_pd_issue_date_before_rve }}
              </div>

              <div
                :style="getCellStyle('reports_status')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ups.reports_status }}
              </div>

              <div
                :style="getCellStyle('reports_status_desc')"
                class="l-table__cell l-table__cell--bordered pre-line"
              >
                {{ row.ups.reports_status_desc }}
              </div>

              <div
                :style="getCellStyle('reports_status_updated_at')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ups.reports_status_updated_at }}
              </div>

              <div
                :style="getCellStyle('reports_requested_at')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ups.reports_requested_at }}
              </div>

              <div
                :style="getCellStyle('aggregated_at')"
                class="l-table__cell l-table__cell--bordered"
              >
                {{ row.ups.aggregated_at }}
              </div>

              <div
                :style="getCellStyle('actions')"
                class="l-table__cell l-table__cell--bordered"
              >
                <div class="flex no-wrap">
                  <q-btn
                    :disable="
                      awaitReportsRequesting || !row.can_request_reports
                    "
                    flat
                    icon="mdi-play"
                    title="Запросить выгрузку отчетов"
                    @click="requestReports(row.ups.id)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="!rows || (Array.isArray(rows) && rows.length === 0)"
        class="q-pa-md"
      >
        Нет данных
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import { createMetaMixin } from "quasar";

  export default {
    name: "UnsoldPremises",

    components: {},

    mixins: [
      createMetaMixin(function () {
        return {
          title: "Нереализованные помещения",
        };
      }),
    ],

    data() {
      return {
        loading: true,
        awaitRows: false,
        awaitReportsRequesting: false,
        rows: [],
        columns: [
          {
            name: "region_name",
            label: "Регион",
            style: "width: 80px;",
          },
          {
            name: "ap_project_name",
            label: "Проект",
            style: "width: 140px;",
          },
          {
            name: "ap_project_serial",
            label: "Номер проекта",
            style: "width: 60px;",
          },
          {
            name: "ap_box_serial",
            label: "Номер корпуса",
            style: "width: 60px;",
          },
          {
            name: "pbo_qty",
            label: "PBO, шт",
            style: "width: 40px;",
          },
          {
            name: "unsold_qty_rosreestr",
            label: "Нереализованные помещения, шт",
            style: "width: 80px;",
          },
          {
            name: "last_pd_issue_date_before_rve",
            label: "Дата последней выписки по сделкам до РВЭ",
            style: "width: 80px;",
          },
          {
            name: "reports_status",
            label: "Статус запроса отчетов",
            style: "width: 80px;",
          },
          {
            name: "reports_status_desc",
            label: "Описание статуса",
            style: "width: 80px;",
          },
          {
            name: "reports_status_updated_at",
            label: "Статус запроса обновлен",
            style: "width: 80px;",
          },
          {
            name: "reports_requested_at",
            label: "Запрос отправлен",
            style: "width: 80px;",
          },
          {
            name: "aggregated_at",
            label: "Агрегировано",
            style: "width: 80px;",
          },
          {
            name: "actions",
            label: "Действия",
            style: "width: 100px;",
          },
        ],
        cellStyles: {},
      };
    },

    async mounted() {
      this.updateColumnStyles(this.columns);
      await this.setRows();
      this.loading = false;
    },

    methods: {
      async setRows() {
        this.awaitRows = true;

        let res = await api.apBox.findUnsoldPremises();
        if (res.status === 200 && res.data.rows) {
          this.rows = res.data.rows;
        }
        if (res.status === 204) {
          this.rows = [];
        }

        this.awaitRows = false;
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      updateColumnStyles(columns) {
        let obj = {};

        columns.forEach((item) => {
          obj[item.name] = item.style;
        });

        this.cellStyles = obj;
      },

      shortness(value, length) {
        return shortness(value, length);
      },

      getStylesForTable() {
        if (this.$q.screen.xs || this.$q.screen.sm) {
          return "";
        }

        const height =
          this.$store.state.windowInnerHeight - (this.$q.screen.md ? 245 : 210);

        return `max-height: ${height}px`;
      },

      async requestReports(upsId) {
        this.awaitReportsRequesting = true;

        api.apBox
          .requestRoomsReports(upsId)
          .then(
            async (res) => {
              this.$q.notify({
                color: "positive",
                message: res.data.message,
              });
              await this.setRows();
            },
            (error) => {
              this.$q.notify({
                color: "negative",
                message: error.response.data.message,
              });
            }
          )
          .finally(() => {
            this.awaitReportsRequesting = false;
          });
      },
    },
  };
</script>
