<template>
  <div class="l-table__row" role="rowgroup">
    <div
      v-if="visibleColumns.includes('serial')"
      :style="getCellStyle('serial')"
      class="l-table__cell"
    />
    <div
      v-if="visibleColumns.includes('region_name')"
      :style="getCellStyle('region_name')"
      class="l-table__cell"
    />
    <div
      v-if="visibleColumns.includes('sales_type')"
      :style="getCellStyle('sales_type')"
      class="l-table__cell"
    />
    <div
      v-if="visibleColumns.includes('project')"
      :style="getCellStyle('project')"
      class="l-table__cell"
    />
    <div
      v-if="visibleColumns.includes('builder')"
      :style="getCellStyle('builder')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.builder_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('developer')"
      :style="getCellStyle('developer')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.developer_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>
    <div
      v-if="visibleColumns.includes('sales_realised')"
      :style="getCellStyle('sales_realised')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.sales_realised_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('floor_max')"
      :style="getCellStyle('floor_max')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.floor_max_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('flat_qty')"
      :style="getCellStyle('flat_qty')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.flat_qty_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('parking_qty')"
      :style="getCellStyle('parking_qty')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.parking_qty_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('date_rve')"
      :style="getCellStyle('date_rve')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.date_rve_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('ceiling_height')"
      :style="getCellStyle('ceiling_height')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.ceiling_height_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('finishing_type')"
      :style="getCellStyle('finishing_type')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.finishing_type_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('living_square')"
      :style="getCellStyle('living_square')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.living_square_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('cadastrals')"
      :style="getCellStyle('cadastrals')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.cadastrals_compared"
        :options="compareOptions"
        dense
        emit-value
        map-options
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('reviewed_by')"
      :style="getCellStyle('reviewed_by')"
      class="l-table__cell"
    />
    <div
      v-if="visibleColumns.includes('reviewed_at')"
      :style="getCellStyle('reviewed_at')"
      class="l-table__cell"
    />
    <div
      v-if="visibleColumns.includes('reviewed_comment')"
      :style="getCellStyle('reviewed_comment')"
      class="l-table__cell"
    />
    <div
      v-if="visibleColumns.includes('actions')"
      :style="getCellStyle('actions')"
      class="l-table__cell"
    />
  </div>
</template>

<script>
  export default {
    name: "CompareTableTopFiltersRow",

    emits: ["filter-compounds"],

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
      visibleColumns: {
        type: Array,
        required: true,
      },
    },

    computed: {
      compareOptions() {
        return [
          {
            value: null,
            label: "Все",
          },
          {
            value: "diff",
            label: "Есть отличия",
          },
          {
            value: "no_diff",
            label: "Нет отличий",
          },
        ];
      },
    },

    data() {
      return {};
    },

    methods: {
      onSearchInput(val) {
        this.$emit("filter-compounds");
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },
    },
  };
</script>
