<template>
  <div class="l-table__row" role="rowgroup">
    <div
      v-if="visibleColumns.includes('serial')"
      :style="getCellStyle('serial')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.serial.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.serial.c"
            :options="compareStringOptions"
            :value="search.serial.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('region_name')"
      :style="getCellStyle('region_name')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.region_name"
        :options="regionNames"
        clearable
        dense
        multiple
        options-dense
        use-chips
        use-input
        @filter="filterRegionNames"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('sales_type')"
      :style="getCellStyle('sales_type')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.sales_type"
        :options="[
          {
            label: 'перспективный',
            value: 'perspective',
          },
          {
            label: 'бронирование',
            value: 'reserv',
          },
          {
            label: 'в реализации',
            value: 'sale',
          },
          {
            label: 'удален',
            value: 'removed',
          },
          {
            label: 'закрытые продажи',
            value: 'close-sale',
          },
          {
            label: 'архив',
            value: 'archive',
          },
          {
            label: 'сделки',
            value: 'deals',
          },
          {
            label: 'заморожен',
            value: 'frozen',
          },
        ]"
        clearable
        map-options
        multiple
        use-chips
        options-dense
        dense
        emit-value
        use-input
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('project')"
      :style="getCellStyle('project')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.project"
        :options="projects"
        clearable
        dense
        options-dense
        use-input
        @filter="filterProject"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('builder')"
      :style="getCellStyle('builder')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.builder"
        :options="builders"
        clearable
        dense
        options-dense
        use-input
        @filter="filterBuilder"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('developer')"
      :style="getCellStyle('developer')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.developer"
        :options="developers"
        clearable
        dense
        options-dense
        use-input
        @filter="filterDeveloper"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('sales_realised')"
      :style="getCellStyle('sales_realised')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.sales_realised.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.sales_realised.c"
            :options="compareNumberOptions"
            :value="search.sales_realised.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('floor_max')"
      :style="getCellStyle('floor_max')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.floor_max.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.floor_max.c"
            :options="compareNumberOptions"
            :value="search.floor_max.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('flat_qty')"
      :style="getCellStyle('flat_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.flat_qty.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.flat_qty.c"
            :options="compareNumberOptions"
            :value="search.flat_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('parking_qty')"
      :style="getCellStyle('parking_qty')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.parking_qty.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.parking_qty.c"
            :options="compareNumberOptions"
            :value="search.parking_qty.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('date_rve')"
      :style="getCellStyle('date_rve')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.date_rve.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.date_rve.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.date_rve.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDateRveFrom">
                <q-date
                  v-model="search.date_rve.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDateRveFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.date_rve.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.date_rve.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.date_rve.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerDateRveTo">
                <q-date
                  v-model="search.date_rve.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerDateRveTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('ceiling_height')"
      :style="getCellStyle('ceiling_height')"
      class="l-table__cell"
    >
      <q-input
        v-model.number="search.ceiling_height"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('finishing_type')"
      :style="getCellStyle('finishing_type')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.finishing_type"
        :options="finishingTypes"
        clearable
        dense
        options-dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('living_square')"
      :style="getCellStyle('living_square')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.living_square.v"
        v-bind="searchInputProps"
        type="number"
        class="appearance-none"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.living_square.c"
            :options="compareNumberOptions"
            :value="search.living_square.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('cadastrals')"
      :style="getCellStyle('cadastrals')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.cadastrals"
        dense
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('reviewed_by')"
      :style="getCellStyle('reviewed_by')"
      class="l-table__cell"
    >
      <q-select
        v-model="search.reviewed_by"
        :options="users"
        clearable
        v-bind="searchSelectProps"
        @update:model-value="onSearchInput"
      />
    </div>

    <div
      v-if="visibleColumns.includes('reviewed_at')"
      :style="getCellStyle('reviewed_at')"
      class="l-table__cell"
    >
      <div class="full-width">
        <div class="flex justify-between items-center">
          <div>
            {{ search.reviewed_at.from || "от" }}
          </div>
          <div>
            <q-icon
              v-if="search.reviewed_at.from"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.reviewed_at.from = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerReviewedAtFrom">
                <q-date
                  v-model="search.reviewed_at.from"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerReviewedAtFrom.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div>
            {{ search.reviewed_at.to || "до" }}
          </div>
          <div>
            <q-icon
              v-if="search.reviewed_at.to"
              class="cursor-pointer"
              color="grey-7"
              name="mdi-close-circle"
              size="sm"
              @click="search.reviewed_at.to = null"
            />

            <q-icon
              class="cursor-pointer"
              color="grey-7"
              name="mdi-calendar"
              size="sm"
            >
              <q-popup-proxy ref="datePickerReviewedAtTo">
                <q-date
                  v-model="search.reviewed_at.to"
                  mask="YYYY-MM-DD"
                  @mouseleave="$refs.datePickerReviewedAtTo.hide()"
                />
              </q-popup-proxy>
            </q-icon>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="visibleColumns.includes('reviewed_comment')"
      :style="getCellStyle('reviewed_comment')"
      class="l-table__cell"
    >
      <q-input
        v-model="search.reviewed_comment.v"
        v-bind="searchInputProps"
        @update:model-value="onSearchInput"
      >
        <template v-slot:prepend>
          <ComparisonSelect
            v-model="search.reviewed_comment.c"
            :options="compareStringOptions"
            :value="search.reviewed_comment.c"
            @update:model-value="onSearchCompareInput"
          />
        </template>
      </q-input>
    </div>

    <div
      v-if="visibleColumns.includes('actions')"
      :style="getCellStyle('actions')"
      class="l-table__cell"
    />
  </div>
</template>

<script>
  import ComparisonSelect from "@/components/ComparisonSelect";
  import {
    COMPARE_NUMBER_OPTIONS,
    COMPARE_STRING_OPTIONS,
    SEARCH_SELECT_PROPS,
    SEARCH_INPUT_PROPS,
  } from "@/utils/batch";
  import api from "@/api";

  export default {
    name: "CompareTableFiltersRow",

    emits: ["set-compounds", "filter-compounds"],

    props: {
      cellStyles: {
        type: Object,
        required: true,
      },
      search: {
        type: Object,
        required: true,
      },
      visibleColumns: {
        type: Array,
        required: true,
      },
    },

    components: {
      ComparisonSelect,
    },

    data() {
      return {
        searchSelectProps: SEARCH_SELECT_PROPS,
        searchInputProps: SEARCH_INPUT_PROPS,
        compareNumberOptions: COMPARE_NUMBER_OPTIONS,
        compareStringOptions: COMPARE_STRING_OPTIONS,
        regionNames: [],
        projects: [],
        builders: [],
        developers: [],
        finishingTypes: [],
        users: [],
      };
    },

    watch: {
      "search.date_rve.from"() {
        this.$emit("filter-compounds");
      },
      "search.date_rve.to"() {
        this.$emit("filter-compounds");
      },
      "search.reviewed_at.from"() {
        this.$emit("filter-compounds");
      },
      "search.reviewed_at.to"() {
        this.$emit("filter-compounds");
      },
    },

    async mounted() {
      this.$emit("set-compounds");

      let [resUsers, resFilters] = await Promise.all([
        api.user.find(),
        api.compound.findFilters(),
      ]);

      this.users = resUsers.data;
      this.regionNames = resFilters.data.regionNames.sort();
      this.projects = resFilters.data.projects.sort();
      this.builders = resFilters.data.builders.sort();
      this.finishingTypes = resFilters.data.finishingTypes.sort();
      // non-reactive for filtering
      this.regionNamesData = resFilters.data.regionNames.sort();
      this.projectsData = resFilters.data.projects.sort();
      this.buildersData = resFilters.data.builders.sort();
      this.developersData = resFilters.data.developers.sort();
    },

    methods: {
      onSearchInput(val) {
        this.$emit("filter-compounds");
      },

      onSearchCompareInput(val) {
        this.$emit("filter-compounds");
      },

      getCellStyle(columnName) {
        return this.cellStyles[columnName];
      },

      filterRegionNames(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.regionNames = this.regionNamesData.filter(
            (i) => i.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      filterProject(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.projects = this.projectsData.filter(
            (i) => i.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      filterBuilder(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.builders = this.buildersData.filter(
            (i) => i.toLowerCase().indexOf(needle) > -1
          );
        });
      },

      filterDeveloper(val, update) {
        update(() => {
          const needle = val.toLowerCase().trim();
          this.developers = this.developersData.filter(
            (i) => i.toLowerCase().indexOf(needle) > -1
          );
        });
      },
    },
  };
</script>
